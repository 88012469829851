import { FC } from 'react';
import { ReactComponent as GuideSvg } from '@/assets/menu/guide.svg';
import { ReactComponent as PermissionSvg } from '@/assets/menu/permission.svg';
import { ReactComponent as DashboardSvg } from '@/assets/menu/dashboard.svg';
import { ReactComponent as AccountSvg } from '@/assets/menu/account.svg';
import { ReactComponent as DocumentationSvg } from '@/assets/menu/documentation.svg';
import { ExportOutlined, GoldOutlined, GroupOutlined, ImportOutlined, UserOutlined, QrcodeOutlined } from '@ant-design/icons';

interface CustomIconProps {
  type: string;
}

export const CustomIcon: FC<CustomIconProps> = (props) => {
  const { type } = props;
  let com = <GuideSvg />;

  if (type === 'user') {
    com = <UserOutlined />;
  } else if (type === 'supplier') {
    com = <ImportOutlined />;
  } else if (type === 'material') {
    com = <GoldOutlined />;
  } else if (type === 'product') {
    com = <ExportOutlined />;
  } else if (type === 'shipment') {
    com = <GroupOutlined />;
  } else if (type === 'qr-code') {
    com = <QrcodeOutlined />;
  } else {
    com = <GuideSvg />;
  }

  return <span className="anticon">{com}</span>;
};
