import { HeaderComponent, MenuComponent } from '@/components/layout';
import { MenuChild, MenuList } from '@/interfaces/layout/menu.interface';
import { LayoutService } from '@/services/layout.service';
import { getFirstPathCode } from '@/utils/getFirstPathCode';
import { getGlobalState } from '@/utils/getGloabal';
import { Drawer, Layout, theme as antTheme } from 'antd';
import { FC, Suspense, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router';
import './index.scss';
import { setGlobalState } from '@/stores/reducer/global.reducer';
import { setMenuList as setLayoutMenuList } from '@/stores/reducer/layout.reducer';
import packageJson from '../../../package.json';

const { Sider, Content, Footer } = Layout;
const WIDTH = 992;

const MainLayoutPage: FC = () => {
  const location = useLocation();
  const [openKey, setOpenkey] = useState<string>();
  const [selectedKey, setSelectedKey] = useState<string>(location.pathname);
  const [menuList, setMenuList] = useState<MenuList>([]);
  const { device, collapsed, locale } = useSelector((state) => state.global);
  const token = antTheme.useToken();

  const isMobile = device === 'MOBILE';
  const dispatch = useDispatch();

  useEffect(() => {
    const code = getFirstPathCode(location.pathname);

    setOpenkey(code);
    setSelectedKey(location.pathname);
  }, [location.pathname]);

  const toggle = () => {
    dispatch(
      setGlobalState({
        collapsed: !collapsed
      })
    );
  };

  const initMenuListAll = (menu: MenuList) => {
    const MenuListAll: MenuChild[] = [];

    menu.forEach((m) => {
      if (!m?.children?.length) {
        MenuListAll.push(m);
      } else {
        m?.children.forEach((mu) => {
          MenuListAll.push(mu);
        });
      }
    });

    return MenuListAll;
  };

  const fetchMenuList = useCallback(async () => {
    const { data } = await LayoutService.getMenuList();

    if (data) {
      setMenuList(data);
      dispatch(setLayoutMenuList(initMenuListAll(data)));
    }
  }, [dispatch]);

  useEffect(() => {
    fetchMenuList();
  }, [fetchMenuList]);

  useEffect(() => {
    if (locale !== 'vi_VN') {
      dispatch(setGlobalState({ locale: 'vi_VN' }));
    }
    window.onresize = () => {
      const { device } = getGlobalState();
      const rect = document.body.getBoundingClientRect();
      const needCollapse = rect.width < WIDTH;

      dispatch(
        setGlobalState({
          device,
          collapsed: needCollapse
        })
      );
    };
  }, [dispatch]);

  return (
    <Layout className="main">
      <HeaderComponent collapsed={collapsed} toggle={toggle} />
      <Layout>
        {!isMobile ? (
          <Sider
            className="main-sider"
            trigger={null}
            width={250}
            collapsible
            style={{ backgroundColor: token.token.colorBgContainer }}
            collapsedWidth={isMobile ? 0 : 80}
            collapsed={collapsed}
            breakpoint="md"
          >
            <MenuComponent
              menuList={menuList}
              openKey={openKey}
              onChangeOpenKey={(k) => setOpenkey(k)}
              selectedKey={selectedKey}
              onChangeSelectedKey={(k) => setSelectedKey(k)}
            />
          </Sider>
        ) : (
          <Drawer width="200" placement="left" bodyStyle={{ padding: 0, height: '100%' }} closable={false} onClose={toggle} open={!collapsed}>
            <MenuComponent
              menuList={menuList}
              openKey={openKey}
              onChangeOpenKey={(k) => setOpenkey(k)}
              selectedKey={selectedKey}
              onChangeSelectedKey={(k) => setSelectedKey(k)}
            />
          </Drawer>
        )}
        <Content className="main-content">
          <div className="main-content-inner">
            <Suspense fallback={null}>
              <Outlet />
            </Suspense>
          </div>
          <Footer style={{ textAlign: 'center', padding: '12px 6px' }}>
            MT NEST ©2023. Version {packageJson.version}. <br className="main-footer-br" />
            Copyright by{' '}
            <a href="https://amitgroup.vn" target="_blank" rel="noreferrer">
              AMIT GROUP
            </a>
          </Footer>
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayoutPage;
