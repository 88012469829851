export const viVN_ProductManagement = {
  'app.product.management.title': 'Quản lý sản phẩm đầu ra',
  'app.product.management.createTitle': 'Thêm sản phẩm đầu ra',
  'app.product.management.createSuccessMessage': 'Thêm sản phẩm đầu ra thành công',
  'app.product.management.updateTitle': 'Chỉnh sửa sản phẩm đầu ra {code}',
  'app.product.management.updateSuccessMessage': 'Lưu thông tin thành công',
  'app.product.management.deleteConfirmTitle': 'Xác nhận xóa',
  'app.product.management.deleteConfirmMessage': 'Bạn có chắc chắn muốn xóa sản phẩm {code}?',
  'app.product.management.deleteSuccessMessage': 'Xóa sản phẩm thành công',
  'app.product.management.deleteMultipleConfirmMessage': 'Bạn có chắc chắn muốn xóa {count} sản phẩm đã chọn?',
  'app.product.management.deleteMultipleSuccessMessage': 'Xóa {count} sản phẩm thành công',
  'app.product.management.selectedRecords': 'Đã chọn {count} kết quả',
  'app.product.management.filterByProductType': 'Lọc theo loại sản phẩm',
  'app.product.management.cannotDeleteProductMessage': 'Không thể xóa vì sản phẩm đầu ra {code} đang được sử dụng trong sản xuất!',
  'app.product.management.cannotDeleteMultipleProductMessage': 'Không thể xóa vì một số sản phẩm đầu ra đang được sử dụng trong sản xuất!'
};
