import { PageData } from '@/interfaces';
import Mock from 'mockjs';
import { getTableData } from '@/utils/get-table-page-data';
import { Response } from '@/core/interfaces/api';

Mock.setup({
  timeout: 1000
});

export type ArrayElementType<T> = T extends (infer U)[] ? U : any; // Mock the real back-end api structure.

interface PageParams {
  pageSize?: number;
  pageNum?: number;
}

export function intercepter<T>(data: T): Response<T>;
export function intercepter<T extends any[]>(data: T, page: PageParams): Response<PageData<T>>;

export function intercepter(data: any, page?: PageParams) {
  if (page) {
    const result = getTableData(Number(page.pageNum), Number(page.pageSize), data);

    return {
      code: 0,
      message: 'Successfully',
      messageCode: 'SUCCESS',
      data: result
    };
  } else {
    if (data && data.code) {
      return data;
    }

    return {
      code: 0,
      message: 'Successfully',
      messageCode: 'SUCCESS',
      data: data
    };
  }
}

export const mock = Mock;
