export const zhCN_TraceabilityResult = {
  'app.traceability.inputCode': '输入追溯码...',
  'app.traceability.notfound': '未找到追溯码',
  'app.traceability.code': '追溯码: {code}',
  'app.traceability.information': '追溯信息',
  'app.traceability.product.information': '产品信息',
  'app.traceability.material.information': '原料信息',
  'app.traceability.supplier.information': '供应商信息',
  'app.traceability.product.name': '产品名称',
  'app.traceability.product.type': '产品类型',
  'app.traceability.product.shipmentCode': '生产批次码',
  'app.traceability.product.description': '产品描述',
  'app.traceability.product.description.value': `- 起源: VIET NAM
  - 保质期:  二十四个月
  - 储存条件: 干燥处、阴凉处，阴凉处并避免阳光直射
  
  - 产品描述: 我们司是国内经认证符合《燕窝产品加工企业卫生技术规范》并按照中国食品安全卫生法的要求建立了本次食品安全追溯 我们公司生产加工的每件燕窝产品均经过干燥、灭菌后独立包装。各项指标均符合国家检测要求。
  `,
  'app.traceability.company.information': '制造商信息',
  'app.traceability.company.name': '制造商名称',
  'app.traceability.company.vietnamRegistration': '商业执照',
  'app.traceability.company.chinaRegistration': '中国商业登记号码',
  'app.traceability.company.address': '制造商地址',
  'app.traceability.company.name.value': '有限公司',
  'app.traceability.company.vietnamRegistration.value': '4201954317',
  'app.traceability.company.chinaRegistration.value': 'CVNM03012401240008',
  'app.traceability.company.address.value': '越南庆和省宁和镇宁东公社范亭村。',
  'app.traceability.product.shift': '生产班次:',
  'app.traceability.product.quantity': '生产数量',
  'app.traceability.product.dateOfManufacture': '生产日期',
  'app.traceability.product.expirationDate': '过期日期',
  'app.traceability.material.code': '原料批次码',
  'app.traceability.material.type': '原料类型',
  'app.traceability.material.importedDate': '收货日期',
  'app.traceability.material.expiredDate': '过期日期',
  'app.traceability.material.weight': 'Trọng lượng nguyên liệu đầu ',
  'app.traceability.supplier.generalInformation': 'Thông tin chung',
  'app.traceability.supplier.code': 'Supplier code',
  'app.traceability.supplier.name': '供应商名称',
  'app.traceability.supplier.status': 'Status',
  'app.traceability.supplier.phone': 'Số điện thoại nguồn nhập',
  'app.traceability.supplier.address': '供应商地区',
  'app.traceability.supplier.diseaseTestingInformation': 'Thông tin kiểm định',
  'app.traceability.supplier.diseaseTestingStatus': '检验状态',
  'app.traceability.supplier.diseaseTestingDate': 'Ngày kiểm định',
  'app.traceability.supplier.diseaseTestingExpiredDate': 'Ngày hết hạn',
  'app.traceability.supplier.representativeInformation': 'Người đại diện',
  'app.traceability.supplier.representativeName': 'Tên người đại diện',
  'app.traceability.supplier.representativePhone': 'Số điện thoại',
  'app.traceability.supplier.representativeEmail': 'Email',
  'app.traceability.supplier.diseaseTestingNo': 'Số đăng ký xét nghiệm',
  'app.traceability.supplier.diseaseTestingDescription': 'Yêu cầu xét nghiệm',
  'app.traceability.supplier.diseaseTestingSamples': 'Số mẫu',
  'app.traceability.supplier.diseaseTestingNotation': 'Ký hiệu mẫu',
  'app.traceability.supplier.diseaseTestingMethod': 'Phương pháp xét nghiệm'
};
