import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createAsyncAction } from '../utils';
import { SortDirection } from '@/interfaces/general';
import { ListMaterialFilterParams, ListMaterialParams, ListMaterialResult, Material } from '@/interfaces/material';
import { MaterialService } from '@/services/material.service';

interface State {
  loading: boolean;
  materials: Material[];
  selectedMaterials: Material[];
  pageIndex: number;
  pageSize: number;
  totalItems: number;
  sortBy: string;
  sortDirection: SortDirection;
  supplierId: number | null;
  materialId: number | null;
  keyword: string | null;
}
const initialState: State = {
  loading: false,
  materials: [],
  selectedMaterials: [],
  pageIndex: 0,
  pageSize: 10,
  totalItems: 0,
  sortBy: 'id',
  sortDirection: 'DESC',
  supplierId: null,
  materialId: null,
  keyword: null
};
const materialSlice = createSlice({
  name: 'material',
  initialState,
  reducers: {
    setLoadingMaterials(state, { payload }: PayloadAction<boolean>) {
      state.loading = payload;
    },
    setMaterialState(state, { payload }: PayloadAction<ListMaterialResult & ListMaterialFilterParams>) {
      state.materials = payload.items ?? [];
      state.pageIndex = payload.pageIndex ?? 0;
      state.pageSize = payload.pageSize ?? 0;
      state.totalItems = payload.totalItems ?? 0;
      state.supplierId = payload.supplierId ?? null;
      state.materialId = payload.materialId ?? null;
      state.keyword = payload.keyword ?? null;
      state.selectedMaterials = state.selectedMaterials.filter((u) => payload.items.find((s) => s.id === u.id));
    },

    addSelectedMaterial(state, { payload }: PayloadAction<Material>) {
      state.selectedMaterials = [...state.selectedMaterials, payload];
    },
    removeSelectedMaterial(state, { payload }: PayloadAction<Material>) {
      state.selectedMaterials = state.selectedMaterials.filter((item) => item.id !== payload.id);
    },
    setSelectedMaterials(state, { payload }: PayloadAction<Material[]>) {
      state.selectedMaterials = payload;
    }
  }
});

export const { setLoadingMaterials, setMaterialState, addSelectedMaterial, removeSelectedMaterial, setSelectedMaterials } = materialSlice.actions;
export default materialSlice.reducer;

export const getMaterials = createAsyncAction((payload: ListMaterialParams) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoadingMaterials(true));
      const res = await MaterialService.listMaterial(payload);
      dispatch(
        setMaterialState({
          ...res.data,
          pageSize: payload.pageSize,
          keyword: payload.keyword,
          materialId: payload.materialId,
          supplierId: payload.supplierId
        })
      );
      dispatch(setLoadingMaterials(false));
      return true;
    } catch (error) {
      dispatch(
        setMaterialState({
          items: [],
          pageIndex: 0,
          totalItems: 0,
          pageSize: payload.pageSize,
          keyword: payload.keyword
        })
      );
      dispatch(setLoadingMaterials(false));
      return Promise.reject(error);
    }
  };
});
