import { ReactComponent as EnUsSvg } from '@/assets/header/en_US.svg';
import { ReactComponent as KoKRSvg } from '@/assets/header/ko_KR.svg';
import { ReactComponent as LanguageSvg } from '@/assets/header/language.svg';
import { ReactComponent as ViVNSvg } from '@/assets/header/vi_VN.svg';
import { ReactComponent as ZhCnSvg } from '@/assets/header/zh_CN.svg';
import logo from '@/assets/logo/logo_yen-800x800.png';
import { TRACEABILITY_TYPE } from '@/enums/qr-code';
import { useLocale } from '@/locales';
import { setGlobalState } from '@/stores/reducer/global.reducer';
import { Dropdown, Input, Layout, theme as antTheme } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
const { Header } = Layout;

interface HeaderProps {
  collapsed?: boolean;
  toggle?: () => void;
}

export const TraceabilityHeader = ({ collapsed, toggle }: HeaderProps) => {
  const { locale, device } = useSelector((state) => state.global);
  const { logged } = useSelector((state) => state.auth);
  const { theme } = useSelector((state) => state.global);
  const navigate = useNavigate();
  const token = antTheme.useToken();
  const dispatch = useDispatch();
  const { formatMessage } = useLocale();
  const location = useLocation();
  const { data } = useSelector((state) => state.traceabilityResult);

  const goHomepage = () => {
    navigate('/auth/login');
  };

  const onSearch = (code: string) => {
    if (code) {
      navigate(`/t/${code.toUpperCase()}`);
    }
  };

  const selectLocale = ({ key }: { key: any }) => {
    dispatch(setGlobalState({ locale: key }));
    localStorage.setItem('locale', key);
  };

  return (
    <>
      <Header className="main-header traceability-header bg-2" style={{ backgroundColor: token.token.colorBgContainer }}>
        {device !== 'MOBILE' && (
          <div className="logo" onClick={goHomepage}>
            <img src={logo} alt="" />
          </div>
        )}
        <div className="main-header-main">
          <Input.Search
            className="traceability-header-search"
            onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
            placeholder={formatMessage({ id: 'app.traceability.inputCode' })}
            allowClear
            onSearch={onSearch}
          />
          {data && data.traceabilityType === TRACEABILITY_TYPE.PRODUCT && (
            <>
              <Dropdown
                className="traceability-header-language"
                menu={{
                  onClick: (info) => selectLocale(info),
                  items: [
                    {
                      key: 'zh_CN',
                      icon: <ZhCnSvg />,
                      disabled: locale === 'zh_CN',
                      label: '简体中文'
                    },
                    {
                      key: 'en_US',
                      icon: <EnUsSvg />,
                      disabled: locale === 'en_US',
                      label: 'English'
                    },
                    // {
                    //   key: 'ko_KR',
                    //   icon: <KoKRSvg />,
                    //   disabled: locale === 'ko_KR',
                    //   label: 'Korea'
                    // },
                    {
                      key: 'vi_VN',
                      icon: <ViVNSvg />,
                      disabled: locale === 'vi_VN',
                      label: 'Việt Nam'
                    }
                  ]
                }}
              >
                <span>
                  <LanguageSvg id="language-change" />
                </span>
              </Dropdown>
            </>
          )}
        </div>
      </Header>
    </>
  );
};
