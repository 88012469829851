import { LoginParams, LoginResult } from '@/interfaces/auth';
import { intercepter, mock } from '../config';

mock.mock('/auth/login', 'post', (config: any) => {
  const body: LoginParams = JSON.parse(config?.body);
  if (body.email !== 'amg.admin@yopmail.com') {
    return intercepter({
      code: -1,
      message: 'Thông tin đăng nhập không đúng',
      messageCode: 'EMAIL_NOT_EXIST',
      data: null
    });
  }

  return intercepter<LoginResult>({
    accessToken: '123456789asdfghjkl',
    accessTokenExpiredAt: '2023-12-31T00:00:00.000Z'
  });
});
