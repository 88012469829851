export const enUS_globalTips = {
  'global.tips.notfound': 'Sorry, the page you visited does not exist.',
  'global.tips.unauthorized': 'Sorry, you are not authorized to access this page.',
  'global.tips.loginResult': 'When you see this page, it means you are logged in.',
  'global.tips.goToLogin': 'Go To Login',
  'global.tips.username': 'Username',
  'global.tips.enterUsernameMessage': 'Please enter your Username!',
  'global.tips.password': 'Password',
  'global.tips.enterPasswordMessage': 'Please enter your Password!',
  'global.tips.invalidPasswordMessage': 'Password is not valid!',
  'global.tips.passwordFormat': 'Password must be at least 8 characters, including uppercase, lowercase, number and special characters.',
  'global.tips.confirmPassword': 'Confirm Password',
  'global.tips.enterConfirmPasswordMessage': 'Please enter your Confirm Password!',
  'global.tips.invalidConfirmPasswordMessage': 'Confirm Password is not matched!',
  'global.tips.rememberUser': 'Remember user',
  'global.tips.login': 'Login',
  'global.tips.backHome': 'Back Home',
  'global.tips.operation': 'Operation',
  'global.tips.authorize': 'Authorize',
  'global.tips.delete': 'Delete',
  'global.tips.create': 'Create',
  'global.tips.modify': 'Modify',
  'global.tips.search': 'Search',
  'global.tips.reset': 'Reset',
  'global.tips.deleteConfirm': 'Do you Want to delete these items?',
  'global.tips.loading': 'Loading...',
  'global.tips.theme.darkTooltip': 'Switch to dark theme',
  'global.tips.theme.lightTooltip': 'Switch to light theme',
  'global.tips.theme.noticeTooltip': 'Notifications',
  'global.tips.forgotPassword': 'Forgot password?',
  'global.tips.forgotPasswordRequestSuccess': 'You have successfully submitted a password reset request for your account:',
  'global.tips.forgotPasswordCheckEmail': 'Please check your email to continue to the next step.',
  'global.tips.email': 'Email',
  'global.tips.enterEmailMessage': 'Please enter your Email!',
  'global.tips.invalidEmailMessage': 'Email is not valid!',
  'global.tips.backToLogin': 'Back to login',
  'global.tips.continue': 'Continue',
  'global.tips.resetPassword': 'Reset password',
  'global.tips.resetPasswordResultSuccess': 'You have successfully reset your account password:',
  'global.tips.resetPasswordRedirectLogin': 'You will be redirected to the login page in {countDown} seconds',
  'global.tips.success': 'Thành công!',
  'global.tips.error': 'Lỗi!'
};
