import AuthLayoutPage from '@/pages/auth';
import ForgotPasswordPage from '@/pages/auth/forgot-password';
import LoginPage from '@/pages/auth/login';
import TraceabilityPage from '@/pages/traceability';
import MainLayoutPage from '@/pages/main';
import { FC, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router';
import { useRoutes } from 'react-router-dom';
import WrapperRouteComponent from './config';
import ResetPasswordPage from '@/pages/auth/reset-password';
import { useNavigate } from 'react-router-dom';

const NotFound = lazy(() => import(/* webpackChunkName: "404'"*/ '@/pages/404'));
const UserManagementPage = lazy(() => import(/* webpackChunkName: "user-management" */ '@/pages/main/user/management'));
const UserProfilePage = lazy(() => import(/* webpackChunkName: "user-management" */ '@/pages/main/user/profile'));
const SupplierManagementPage = lazy(() => import(/* webpackChunkName: "supplier-management" */ '@/pages/main/supplier/management'));
const SupplierCreatePage = lazy(() => import(/* webpackChunkName: "supplier-management" */ '@/pages/main/supplier/create'));
const SupplierUpdatePage = lazy(() => import(/* webpackChunkName: "supplier-management" */ '@/pages/main/supplier/update'));
const MaterialManagementPage = lazy(() => import(/* webpackChunkName: "material-management" */ '@/pages/main/material/management'));
const MaterialCreatePage = lazy(() => import(/* webpackChunkName: "material-create" */ '@/pages/main/material/create'));
const MaterialUpdatePage = lazy(() => import(/* webpackChunkName: "material-update" */ '@/pages/main/material/update'));
const ProductManagementPage = lazy(() => import(/* webpackChunkName: "product-management" */ '@/pages/main/product/management'));
const ProductCreatePage = lazy(() => import(/* webpackChunkName: "product-create" */ '@/pages/main/product/create'));
const ProductUpdatePage = lazy(() => import(/* webpackChunkName: "product-update" */ '@/pages/main/product/update'));
const ShipmentManagementPage = lazy(() => import(/* webpackChunkName: "shipment-management" */ '@/pages/main/shipment/management'));
const ShipmentCreatePage = lazy(() => import(/* webpackChunkName: "shipment-create" */ '@/pages/main/shipment/create'));
const ShipmentUpdatePage = lazy(() => import(/* webpackChunkName: "shipment-update" */ '@/pages/main/shipment/update'));
const QrCodeManagementPage = lazy(() => import(/* webpackChunkName: "qr-code-management" */ '@/pages/main/qr-code/management'));
const QrCodeDetailPage = lazy(() => import(/* webpackChunkName: "qr-code-detail" */ '@/pages/main/qr-code/detail'));
const TraceabilityResultPage = lazy(() => import(/* webpackChunkName: "traceability-result" */ '@/pages/traceability/result'));
const RedirectToResult = () => {
  const code = window.location.pathname
    .split('/')
    .filter((s) => s)
    .pop() as any;

  return <Navigate to={`/t/${code}`} />;
};

const routeList: RouteObject[] = [
  {
    path: '/auth',
    element: <WrapperRouteComponent element={<AuthLayoutPage />} titleId="" />,
    children: [
      {
        path: '',
        element: <Navigate to="login" />
      },
      {
        path: 'login',
        element: <WrapperRouteComponent element={<LoginPage />} titleId="title.login" />
      },
      {
        path: 'forgot-password',
        element: <WrapperRouteComponent element={<ForgotPasswordPage />} titleId="title.forgotPassword" />
      },
      {
        path: 'reset-password',
        element: <WrapperRouteComponent element={<ResetPasswordPage />} titleId="title.resetPassword" />
      },
      {
        path: '*',
        element: <Navigate to="login" />
      }
    ]
  },
  {
    path: '/',
    element: <WrapperRouteComponent element={<MainLayoutPage />} auth={true} titleId="" />,
    children: [
      {
        path: '',
        element: <Navigate to="user/profile" />
      },
      {
        path: 'user/profile',
        element: <WrapperRouteComponent element={<UserProfilePage />} titleId="title.user.profile" />
      },
      {
        path: 'user',
        element: <WrapperRouteComponent element={<UserManagementPage />} titleId="title.user.management" permissions={['USER_MANAGEMENT']} />
      },
      {
        path: 'supplier',
        element: (
          <WrapperRouteComponent element={<SupplierManagementPage />} titleId="title.supplier.management" permissions={['SUPPLIER_MANAGEMENT']} />
        )
      },
      {
        path: 'supplier/create',
        element: <WrapperRouteComponent element={<SupplierCreatePage />} titleId="title.supplier.create" permissions={['SUPPLIER_MANAGEMENT']} />
      },
      {
        path: 'supplier/:id/update',
        element: <WrapperRouteComponent element={<SupplierUpdatePage />} titleId="title.supplier.update" permissions={['SUPPLIER_MANAGEMENT']} />
      },
      {
        path: 'material',
        element: (
          <WrapperRouteComponent
            element={<MaterialManagementPage />}
            titleId="title.material.management"
            permissions={['SUPPLIER_MATERIAL_MANAGEMENT']}
          />
        )
      },
      {
        path: 'material/create',
        element: (
          <WrapperRouteComponent element={<MaterialCreatePage />} titleId="title.material.create" permissions={['SUPPLIER_MATERIAL_MANAGEMENT']} />
        )
      },
      {
        path: 'material/:id/update',
        element: (
          <WrapperRouteComponent element={<MaterialUpdatePage />} titleId="title.material.update" permissions={['SUPPLIER_MATERIAL_MANAGEMENT']} />
        )
      },
      {
        path: 'product',
        element: <WrapperRouteComponent element={<ProductManagementPage />} titleId="title.product.management" permissions={['PRODUCT_MANAGEMENT']} />
      },
      {
        path: 'product/create',
        element: <WrapperRouteComponent element={<ProductCreatePage />} titleId="title.product.create" permissions={['PRODUCT_MANAGEMENT']} />
      },
      {
        path: 'product/:id/update',
        element: <WrapperRouteComponent element={<ProductUpdatePage />} titleId="title.product.update" permissions={['PRODUCT_MANAGEMENT']} />
      },
      {
        path: 'shipment',
        element: (
          <WrapperRouteComponent element={<ShipmentManagementPage />} titleId="title.shipment.management" permissions={['MANUFACTURE_MANAGEMENT']} />
        )
      },
      {
        path: 'shipment/create',
        element: <WrapperRouteComponent element={<ShipmentCreatePage />} titleId="title.shipment.create" permissions={['MANUFACTURE_MANAGEMENT']} />
      },
      {
        path: 'shipment/:id/update',
        element: <WrapperRouteComponent element={<ShipmentUpdatePage />} titleId="title.shipment.update" permissions={['MANUFACTURE_MANAGEMENT']} />
      },
      {
        path: 'qr-code',
        element: (
          <WrapperRouteComponent element={<QrCodeManagementPage />} titleId="title.qrCode.management" permissions={['TRACEABILITY_MANAGEMENT']} />
        )
      },
      {
        path: 'qr-code/:id',
        element: <WrapperRouteComponent element={<QrCodeDetailPage />} titleId="title.qrCode.detail" permissions={['TRACEABILITY_MANAGEMENT']} />
      },
      {
        path: '*',
        element: <WrapperRouteComponent element={<NotFound />} titleId="title.notFount" />
      }
    ]
  },
  {
    path: '/traceability/:code',
    element: <RedirectToResult />
  },
  {
    path: '/t',
    element: <WrapperRouteComponent element={<TraceabilityPage />} titleId="title.traceability" />,
    children: [
      {
        path: '',
        element: <WrapperRouteComponent element={<NotFound />} titleId="title.notFount" />
      },
      {
        path: ':code',
        element: <WrapperRouteComponent element={<TraceabilityResultPage />} titleId="title.traceability.result" />
      }
    ]
  }
];

const RenderRouter: FC = () => {
  const element = useRoutes(routeList);

  return element;
};

export const History: any = {
  navigate: null,
  push: (page: any, ...rest: any) => (History.navigate as any)(page, ...rest)
};

export const NavigateSetter = () => {
  History.navigate = useNavigate();

  return null;
};

export default RenderRouter;
