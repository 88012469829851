export const enUS_TraceabilityResult = {
  'app.traceability.inputCode': 'Enter the traceability code...',
  'app.traceability.notfound': 'Traceability code not found',
  'app.traceability.code': 'Traceability code: {code}',
  'app.traceability.information': 'Traceability Information',
  'app.traceability.product.information': 'Product Information',
  'app.traceability.material.information': 'Ingredient information',
  'app.traceability.supplier.information': 'Supplier information',
  'app.traceability.product.name': 'Product name',
  'app.traceability.product.type': 'Product type',
  'app.traceability.product.shipmentCode': 'Manufacturing batch code',
  'app.traceability.product.description': 'Product description',
  'app.traceability.product.description.value': `- Country of Origin: Viet Nam
  - Expiration Date: 24 months
  - Storage Condition: dry, cool place away from direct sunlight
  - Product description: Our company is a bird's nest manufacturer that is certified to comply with "hygienic regulations for bird's nest product processing enterprises" and has established safe traceability. This product  complies with the requirements of China's food safety and hygiene law.
  
  Each bird's nest product produced and processed by our company is individually packaged after drying and sterilization. All indicators comply with national requirements.
  `,
  'app.traceability.company.information': 'Company Information',
  'app.traceability.company.name': 'Company name',
  'app.traceability.company.vietnamRegistration': 'Vietnam registration number',
  'app.traceability.company.chinaRegistration': 'China registration number',
  'app.traceability.company.address': 'Company address',
  'app.traceability.company.name.value': 'MT NEST CO., LTD',
  'app.traceability.company.vietnamRegistration.value': '4201954317',
  'app.traceability.company.chinaRegistration.value': 'CVNM03012401240008',
  'app.traceability.company.address.value': 'Van Dinh Hamlet, Ninh Dong Commune, Ninh Hoa Town, Khanh Hoa Province, Vietnam',
  'app.traceability.product.shift': 'Production shift',
  'app.traceability.product.quantity': 'Production quantity',
  'app.traceability.product.dateOfManufacture': 'Production date',
  'app.traceability.product.expirationDate': 'Expiration date',
  'app.traceability.material.code': 'Ingredient Batch code',
  'app.traceability.material.type': 'Ingredient type',
  'app.traceability.material.importedDate': 'Received date',
  'app.traceability.material.expiredDate': 'Expiration date',
  'app.traceability.material.weight': 'Trọng lượng nguyên liệu đầu ',
  'app.traceability.supplier.generalInformation': 'Thông tin chung',
  'app.traceability.supplier.code': 'Supplier code',
  'app.traceability.supplier.name': 'Supplier name',
  'app.traceability.supplier.status': 'Status',
  'app.traceability.supplier.phone': 'Số điện thoại nguồn nhập',
  'app.traceability.supplier.address': 'Supplier area',
  'app.traceability.supplier.diseaseTestingInformation': 'Thông tin kiểm định',
  'app.traceability.supplier.diseaseTestingStatus': 'Inspection status',
  'app.traceability.supplier.diseaseTestingDate': 'Ngày kiểm định',
  'app.traceability.supplier.diseaseTestingExpiredDate': 'Ngày hết hạn',
  'app.traceability.supplier.representativeInformation': 'Người đại diện',
  'app.traceability.supplier.representativeName': 'Tên người đại diện',
  'app.traceability.supplier.representativePhone': 'Số điện thoại',
  'app.traceability.supplier.representativeEmail': 'Email',
  'app.traceability.supplier.diseaseTestingNo': 'Số đăng ký xét nghiệm',
  'app.traceability.supplier.diseaseTestingDescription': 'Yêu cầu xét nghiệm',
  'app.traceability.supplier.diseaseTestingSamples': 'Số mẫu',
  'app.traceability.supplier.diseaseTestingNotation': 'Ký hiệu mẫu',
  'app.traceability.supplier.diseaseTestingMethod': 'Phương pháp xét nghiệm'
};
