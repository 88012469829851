export const viVN_userManagement = {
  'app.user.management.title': 'Quản lý người dùng',
  'app.user.management.createTitle': 'Thêm người dùng mới',
  'app.user.management.createSuccessMessage': 'Tạo người dùng thành công',
  'app.user.management.deleteConfirmMessage': 'Bạn có chắc chắn muốn xóa người dùng {email} không?',
  'app.user.management.deleteConfirmTitle': 'Xác nhận xóa người dùng',
  'app.user.management.deleteSuccessMessage': 'Xóa người dùng {email} thành công',
  'app.user.management.updateTitle': 'Chỉnh sửa người dùng',
  'app.user.management.updateSuccessMessage': 'Chỉnh sửa người dùng thành công'
};
