export const koKR_account = {
  'app.settings.menuMap.basic': '기본 설정',
  'app.settings.menuMap.security': '보안 설정',
  'app.settings.menuMap.binding': '계정 바인딩',
  'app.settings.menuMap.notification': '새 메시지 알림',
  'app.settings.basic.avatar': '화신',
  'app.settings.basic.change-avatar': '아바타 변경',
  'app.settings.basic.email': '이메일',
  'app.settings.basic.email-message': '이메일을 입력해주세요!',
  'app.settings.basic.nickname': '별명',
  'app.settings.basic.nickname-message': '닉네임을 입력해주세요!',
  'app.settings.basic.profile': '개인 프로필',
  'app.settings.basic.profile-message': '개인 프로필을 입력해주세요!',
  'app.settings.basic.profile-placeholder': '간단한 자기 소개',
  'app.settings.basic.country': '국가/지역',
  'app.settings.basic.country-message': '국가를 입력하세요!',
  'app.settings.basic.geographic': '주 또는 시',
  'app.settings.basic.geographic-message': '지리적 정보를 입력하세요!',
  'app.settings.basic.address': '거리 주소',
  'app.settings.basic.address-message': '주소를 입력해주세요!',
  'app.settings.basic.phone': '전화 번호',
  'app.settings.basic.phone-message': '전화번호를 입력해주세요!',
  'app.settings.basic.update': '업데이트 정보',
  'app.settings.security.strong': '강한',
  'app.settings.security.medium': '중간',
  'app.settings.security.weak': '약한',
  'app.settings.security.password': '계정 비밀번호',
  'app.settings.security.password-description': '현재 비밀번호 강도',
  'app.settings.security.phone': '보안 전화',
  'app.settings.security.phone-description': '바운드 전화',
  'app.settings.security.question': '보안 질문',
  'app.settings.security.question-description': '보안 질문이 설정되지 않았으며 보안 정책은 계정 보안을 효과적으로 보호할 수 있습니다.',
  'app.settings.security.email': '백업 이메일',
  'app.settings.security.email-description': '바운드 이메일',
  'app.settings.security.mfa': 'MFA 장치',
  'app.settings.security.mfa-description': '바인딩되지 않은 MFA 장치는 바인딩 후 두 번 확인 가능',
  'app.settings.security.modify': '수정하다',
  'app.settings.security.set': '세트',
  'app.settings.security.bind': '묶다',
  'app.settings.binding.taobao': '제본 Taobao',
  'app.settings.binding.taobao-description': '현재 바인딩 해제된 타오바오 계정',
  'app.settings.binding.alipay': '바인딩 알리페이',
  'app.settings.binding.alipay-description': '현재 바인딩되지 않은 Alipay 계정',
  'app.settings.binding.dingding': '바인딩 딩톡',
  'app.settings.binding.dingding-description': '현재 바인딩 해제된 DingTalk 계정',
  'app.settings.binding.bind': '묶다',
  'app.settings.notification.password': '계정 비밀번호',
  'app.settings.notification.password-description': '다른 사용자의 메시지는 스테이션 편지의 형태로 알려드립니다.',
  'app.settings.notification.messages': '시스템 메시지',
  'app.settings.notification.messages-description': '시스템 메시지는 스테이션 레터 형태로 알려드립니다.',
  'app.settings.notification.todo': '할 일 알림',
  'app.settings.notification.todo-description': '할 일 목록은 역에서 편지 형태로 알려드립니다.',
  'app.settings.open': '열려 있는',
  'app.settings.close': '닫다'
};
