export const viVN_SupplierInformation = {
  'app.supplier.information.generalInformation': 'Thông tin chung',
  'app.supplier.information.code': 'Mã nguồn nhập',
  'app.supplier.information.name': 'Tên nguồn nhập',
  'app.supplier.information.namePlaceholder': 'Nguyễn Văn A',
  'app.supplier.information.enterNameMessage': 'Tên nguồn nhập không được bỏ trống',
  'app.supplier.information.accreditation': 'Kiểm định',
  'app.supplier.information.accreditationPlaceholder': 'Đã kiểm định',
  'app.supplier.information.accreditationTrue': 'Đã kiểm định',
  'app.supplier.information.accreditationFalse': 'Chưa kiểm định',
  'app.supplier.information.diseaseTestingAt': 'Ngày kiểm định',
  'app.supplier.information.diseaseTestingAtPlaceholder': 'dd/mm/yyyy',
  'app.supplier.information.selectDiseaseTestingAtMessage': 'Ngày kiểm định không được bỏ trống',
  'app.supplier.information.diseaseTestingExpiredAt': 'Hạn kiểm định',
  'app.supplier.information.diseaseTestingExpiredAtPlaceholder': 'dd/mm/yyyy',
  'app.supplier.information.selectDiseaseTestingExpiredAtMessage': 'Hạn kiểm định không được bỏ trống',
  'app.supplier.information.invalidDiseaseTestingExpiredAtMessage': 'Hạn kiểm định không hợp lệ',
  'app.supplier.information.selectAccreditationMessage': 'Vui lòng chọn tình trạng kiểm định',
  'app.supplier.information.phone': 'Số điện thoại',
  'app.supplier.information.phonePlaceholder': '0123456789',
  'app.supplier.information.enterPhoneMessage': 'Số điện thoại không được bỏ trống',
  'app.supplier.information.invalidPhoneMessage': 'Số điện thoại không hợp lệ',
  'app.supplier.information.contactInformation': 'Thông tin liên hệ',
  'app.supplier.information.representative': 'Người liên hệ',
  'app.supplier.information.representativeName': 'Họ và tên',
  'app.supplier.information.representativeNamePlaceholder': 'Nguyễn Văn A',
  'app.supplier.information.enterRepresentativeNameMessage': 'Tên không được bỏ trống',
  'app.supplier.information.representativePhone': 'Số điện thoại',
  'app.supplier.information.representativePhonePlaceholder': '0123456789',
  'app.supplier.information.enterRepresentativePhoneMessage': 'Số điện thoại không được bỏ trống',
  'app.supplier.information.invalidRepresentativePhoneMessage': 'Số điện thoại không hợp lệ',
  'app.supplier.information.representativeEmail': 'Email',
  'app.supplier.information.representativeEmailPlaceholder': 'nguyenvana@gmail.com',
  'app.supplier.information.enterRepresentativeEmailMessage': 'Email không được bỏ trống',
  'app.supplier.information.invalidRepresentativeEmailMessage': 'Email không hợp lệ',
  'app.supplier.information.location': 'Vị trí vùng nuôi',
  'app.supplier.information.province': 'Tỉnh/Thành phố',
  'app.supplier.information.provincePlaceholder': 'Thành phố Hồ Chí Minh',
  'app.supplier.information.selectProvinceMessage': 'Chưa chọn tỉnh/thành phố',
  'app.supplier.information.district': 'Quận/Huyện',
  'app.supplier.information.districtPlaceholder': 'Quận 1',
  'app.supplier.information.selectDistrictMessage': 'Chưa chọn quận/huyện',
  'app.supplier.information.ward': 'Phường/Xã',
  'app.supplier.information.wardPlaceholder': 'Phường 1',
  'app.supplier.information.selectWardMessage': 'Chưa chọn phường/xã',
  'app.supplier.information.address': 'Địa chỉ chi tiết',
  'app.supplier.information.addressPlaceholder': 'Số 1, đường 1, phường 1, quận 1, thành phố Hồ Chí Minh',
  'app.supplier.information.enterAddressMessage': 'Địa chỉ không được bỏ trống',
  'app.supplier.information.longitude': 'Kinh độ',
  'app.supplier.information.longitudePlaceholder': '106.629663',
  'app.supplier.information.enterLongitudeMessage': 'Kinh độ không được bỏ trống',
  'app.supplier.information.invalidLongitudeMessage': 'Kinh độ không hợp lệ',
  'app.supplier.information.latitude': 'Vĩ độ',
  'app.supplier.information.latitudePlaceholder': '10.823099',
  'app.supplier.information.enterLatitudeMessage': 'Vĩ độ không được bỏ trống',
  'app.supplier.information.invalidLatitudeMessage': 'Vĩ độ không hợp lệ',
  'app.supplier.information.description': 'Mô tả',
  'app.supplier.information.descriptionPlaceholder': 'Nhập mô tả',
  'app.supplier.information.diseaseTestingNo': 'Số đăng ký xét nghiệm',
  'app.supplier.information.diseaseTestingDescription': 'Yêu cầu xét nghiệm',
  'app.supplier.information.diseaseTestingSamples': 'Số mẫu',
  'app.supplier.information.diseaseTestingNotation': 'Ký hiệu mẫu',
  'app.supplier.information.diseaseTestingMethod': 'Phương pháp xét nghiệm'
};
