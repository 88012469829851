import { ForgotPasswordParams } from '@/interfaces/auth';
import { LocaleFormatter, useLocale } from '@/locales';
import { AuthService } from '@/services/auth.service';
import { Button, Form, Input, Typography } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './index.scss';
import { useNotification } from '@/hooks';

const initialValues: ForgotPasswordParams = {
  email: ''
};

export function ForgotPasswordForm() {
  const { formatMessage } = useLocale();
  const [isRequesting, setIsRequesting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const notify = useNotification();
  const [form] = Form.useForm();
  const [hasErrors, setHasErrors] = useState(true);
  const navigate = useNavigate();

  const onFinished = async (form: ForgotPasswordParams) => {
    try {
      setIsRequesting(true);
      await AuthService.forgotPassword(form);
      setEmail(form.email);
      setIsSubmitted(true);
    } catch (error) {
      notify.error(formatMessage({ id: 'global.tips.error' }), error);
    } finally {
      setIsRequesting(false);
    }
  };

  const onFieldsChange = () => {
    const errors = form.getFieldsError();
    const hasError = errors.some((item) => item.errors.length > 0);
    setHasErrors(hasError);
  };

  return (
    <>
      {!isSubmitted ? (
        <Form<ForgotPasswordParams>
          form={form}
          onFieldsChange={onFieldsChange}
          onFinish={onFinished}
          className="auth-form forgot-password-form"
          initialValues={initialValues}
        >
          <Form.Item
            name="email"
            label={formatMessage({ id: 'global.tips.email' })}
            required={false}
            colon={false}
            labelCol={{ offset: 0, span: 24 }}
            rules={[
              {
                required: true,
                message: formatMessage({
                  id: 'global.tips.enterEmailMessage'
                })
              },
              {
                type: 'email',
                message: formatMessage({
                  id: 'global.tips.invalidEmailMessage'
                })
              }
            ]}
          >
            <Input
              placeholder={formatMessage({
                id: 'global.tips.email'
              })}
            />
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
            <Link to="/auth/login" className="auth-form-link">
              <LocaleFormatter id="global.tips.backToPageLogin" />
            </Link>
          </Form.Item>
          <Form.Item className="login-form-footer">
            <Button loading={isRequesting} htmlType="submit" type="primary" className="login-form_button" size="large" disabled={hasErrors}>
              <LocaleFormatter id="global.tips.continue" />
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <div className="auth-result">
          <Typography.Paragraph>{formatMessage({ id: 'global.tips.forgotPasswordRequestSuccess' })}</Typography.Paragraph>
          <Typography.Paragraph>
            <a href={`mailto:${email}`}>{email}</a>
          </Typography.Paragraph>
          <Typography.Paragraph>
            {formatMessage({
              id: 'global.tips.forgotPasswordCheckEmail'
            })}
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Button type="primary" size="large" onClick={() => navigate('/auth/login')}>
              <LocaleFormatter id="global.tips.backToLogin" />
            </Button>
          </Typography.Paragraph>
        </div>
      )}
    </>
  );
}
