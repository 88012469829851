import { CommonService } from '@/core/interfaces/service';
import { ApiService } from '@/core/services/api.service';
import {
  CreateShipmentParams,
  CreateShipmentResult,
  DeleteShipmentParams,
  DeleteMultipleShipmentParams,
  DetailShipmentParams,
  DetailShipmentResult,
  ListShipmentParams,
  ListShipmentResult,
  UpdateShipmentParams,
  UpdateShipmentResult,
  GenerateQrShipmentParams,
  GenerateQrShipmentResult
} from '@/interfaces/shipment';

export const ShipmentEndpoint = {
  List: '/manufactures/search',
  Create: '/manufactures/add',
  Update: '/manufactures/edit',
  Delete: '/manufactures/delete',
  Detail: '/manufactures/detail',
  DeleteMultiple: '/manufactures/delete-multiple',
  GenerateQr: '/manufactures/generate-qr',
  MaterialWeightUsed: '/manufactures/supplier-material-weight-used'
};

class ShipmentService implements CommonService {
  static instance: ShipmentService;

  constructor() {
    if (ShipmentService.instance) {
      throw new Error('Error: Instantiation failed: Use ShipmentService.getInstance() instead of new.');
    }
    ShipmentService.instance = this;
  }

  static getInstance() {
    if (!ShipmentService.instance) {
      ShipmentService.instance = new ShipmentService();
    }
    return ShipmentService.instance;
  }

  createShipment(formData: CreateShipmentParams) {
    return ApiService.post<CreateShipmentResult>(ShipmentEndpoint.Create, formData);
  }

  listShipment(params: ListShipmentParams) {
    return ApiService.post<ListShipmentResult>(ShipmentEndpoint.List, params);
  }

  deleteShipment(params: DeleteShipmentParams) {
    return ApiService.post(ShipmentEndpoint.Delete, params);
  }

  detailShipment(params: DetailShipmentParams) {
    return ApiService.post<DetailShipmentResult>(ShipmentEndpoint.Detail, params);
  }

  updateShipment(formData: UpdateShipmentParams) {
    return ApiService.post<UpdateShipmentResult>(ShipmentEndpoint.Update, formData);
  }

  deleteMultipleShipments(params: DeleteMultipleShipmentParams) {
    return ApiService.post<DeleteMultipleShipmentParams>(ShipmentEndpoint.DeleteMultiple, params);
  }

  generateQr(params: GenerateQrShipmentParams) {
    return ApiService.post<GenerateQrShipmentResult>(ShipmentEndpoint.GenerateQr, params);
  }

  getMaterialWeightUsed(params: any) {
    return ApiService.post<{ id: number; supplierMaterialWeightUsed: number; supplierMaterialWeightRemain: number }>(
      ShipmentEndpoint.MaterialWeightUsed,
      params
    );
  }
}

const instance = ShipmentService.getInstance();

export { instance as ShipmentService };
