export const viVN_globalTips = {
  'global.tips.notfound': 'Xin lỗi, trang bạn đã truy cập không tồn tại.',
  'global.tips.unauthorized': 'Sorry, you are not authorized to access this page.',
  'global.tips.loginResult': 'When you see this page, it means you are logged in.',
  'global.tips.goToLogin': 'Go To Login',
  'global.tips.username': 'Tên người dùng',
  'global.tips.enterUsernameMessage': 'Vui lòng nhập tên người dùng của bạn!',
  'global.tips.password': 'Mật khẩu',
  'global.tips.enterPasswordMessage': 'Vui lòng nhập mật khẩu của bạn!',
  'global.tips.invalidPasswordMessage': 'Mật khẩu không hợp lệ!',
  'global.tips.passwordFormat': 'Mật khẩu phải có ít nhất 8 ký tự, bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt.',
  'global.tips.confirmPassword': 'Xác nhận mật khẩu',
  'global.tips.enterConfirmPasswordMessage': 'Vui lòng nhập lại mật khẩu của bạn!',
  'global.tips.invalidConfirmPasswordMessage': 'Mật khẩu xác nhận không khớp!',
  'global.tips.rememberUser': 'Ghi nhớ đăng nhập',
  'global.tips.login': 'Đăng nhập',
  'global.tips.backHome': 'Quay lại trang chủ',
  'global.tips.operation': 'Operation',
  'global.tips.authorize': 'Authorize',
  'global.tips.delete': 'Xóa',
  'global.tips.create': 'Tạo mới',
  'global.tips.modify': 'Chỉnh sửa',
  'global.tips.search': 'Tìm kiếm',
  'global.tips.searchPlaceholder': 'Nhập từ khóa tìm kiếm',
  'global.tips.reset': 'Làm mới',
  'global.tips.cancel': 'Hủy',
  'global.tips.deleteConfirm': 'Do you want to delete these items?',
  'global.tips.loading': 'Loading...',
  'global.tips.theme.darkTooltip': 'Switch to dark theme',
  'global.tips.theme.lightTooltip': 'Switch to light theme',
  'global.tips.theme.noticeTooltip': 'Notifications',
  'global.tips.forgotPassword': 'Quên mật khẩu?',
  'global.tips.forgotPasswordRequestSuccess': 'Bạn đã thành công gửi yêu cầu đặt lại mật khẩu cho tài khoản:',
  'global.tips.forgotPasswordCheckEmail': 'Vui lòng kiểm tra email của bạn để tiếp tục bước tiếp theo.',
  'global.tips.email': 'Email',
  'global.tips.enterEmailMessage': 'Vui lòng nhập email của bạn!',
  'global.tips.invalidEmailMessage': 'Email không hợp lệ!',
  'global.tips.backToPageLogin': 'Quay lại trang đăng nhập',
  'global.tips.backToLogin': 'Quay lại đăng nhập',
  'global.tips.continue': 'Tiếp tục',
  'global.tips.resetPassword': 'Đặt lại mật khẩu',
  'global.tips.resetPasswordResultSuccess': 'Bạn đã thành công đặt lại mật khẩu cho tài khoản:',
  'global.tips.resetPasswordRedirectLogin': 'Bạn sẽ được chuyển hướng đến trang đăng nhập trong {countDown} giây',
  'global.tips.success': 'Thành công!',
  'global.tips.error': 'Lỗi!',
  'global.tips.loginSuccess': 'Đăng nhập thành công!',
  'global.tips.save': 'Lưu',
  'global.tips.add': 'Thêm',
  'global.tips.action': 'Hành động',
  'global.tips.viewQR': 'Xem mã QR',
  'global.tips.createQR': 'Tạo mã QR',
  'global.tips.date': 'Ngày',
  'global.tips.month': 'Tháng',
  'global.tips.year': 'Năm',
  'global.tips.upload': 'Tải lên',
  'global.tips.deleteSelected': 'Xóa đã chọn',
  'global.tips.all': 'Tất cả',
  'global.tips.alert': 'Cảnh báo',
  'global.tips.ok': 'OK',
  'global.tips.empty': 'Trống',
  'global.tips.confirm': 'Xác nhận',
  'global.tips.shortCreate': 'Tạo',
  'global.tips.close': 'Đóng',
  'global.tips.activate': 'Kích hoạt',
  'global.tips.deactivate': 'Hủy kích hoạt',
  'global.tips.viewDetail': 'Xem chi tiết',
  'global.tips.reCreate': 'Tạo lại'
};
