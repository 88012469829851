import { viVN_avatorDropMenu } from './avatorDropMenu';
import { viVN_userManagement } from './management';
import { viVN_profile } from './profile';
import { viVN_tagsViewDropMenu } from './tagsViewDropMenu';
import { viVN_title } from './title';
export const viVN_user = {
  ...viVN_avatorDropMenu,
  ...viVN_tagsViewDropMenu,
  ...viVN_title,
  ...viVN_userManagement,
  ...viVN_profile
};
