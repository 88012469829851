import { zhCN_account } from './account';
import { zhCN_avatorDropMenu } from './user/avatorDropMenu';
import { zhCN_tagsViewDropMenu } from './user/tagsViewDropMenu';
import { zhCN_title } from './user/title';
import { zhCN_globalTips } from './global/tips';
import { zhCN_notice } from './notice';
import { zhCN_profile } from './profile';
import { zhCN_Traceability } from './traceability';

const zh_CN = {
  ...zhCN_account,
  ...zhCN_avatorDropMenu,
  ...zhCN_tagsViewDropMenu,
  ...zhCN_title,
  ...zhCN_globalTips,
  ...zhCN_notice,
  ...zhCN_profile,
  ...zhCN_Traceability
};

export default zh_CN;
