import { useNotification } from '@/hooks';
import { useQuery } from '@/hooks/useQuery';
import { ResetPasswordParams } from '@/interfaces/auth/reset-password.interface';
import { LocaleFormatter, useLocale } from '@/locales';
import { AuthService } from '@/services/auth.service';
import { Button, Form, FormInstance, Input, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const initialValues = {
  sessionId: '',
  password: '',
  confirmPassword: ''
};

export interface ResetPasswordFormFields {
  sessionId: string;
  password: string;
  confirmPassword: string;
}

export const ResetPasswordForm: any = () => {
  const [isRequesting, setIsRequesting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const queryParams = useQuery();
  const { formatMessage } = useLocale();
  const [form]: [FormInstance<ResetPasswordFormFields>] = Form.useForm();
  const [countDown, setCountDown] = useState(0);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const notify = useNotification();
  const [hasErrors, setHasErrors] = useState(true);

  const onFinished = async (form: ResetPasswordFormFields) => {
    try {
      setIsRequesting(true);
      const data = {
        newPassword: form.password,
        sessionId: form.sessionId
      };
      await AuthService.resetPassword(data);
      setIsSubmitted(true);
    } catch (error) {
      notify.error(formatMessage({ id: 'global.tips.error' }), error);
    } finally {
      setIsRequesting(false);
    }
  };

  const startCountDown = () => {
    setCountDown(5);
  };

  const onFieldsChange = () => {
    const errors = form.getFieldsError();
    const hasError = errors.some((item) => item.errors.length > 0);
    setHasErrors(hasError);
  };

  useEffect(() => {
    if (isSubmitted) {
      if (countDown > 0) {
        setTimeout(() => {
          setCountDown(countDown - 1);
        }, 1000);
      } else if (countDown === 0) {
        navigate('/auth/login');
      }
    }
  }, [countDown]);

  useEffect(() => {
    if (isSubmitted) {
      startCountDown();
    }
  }, [isSubmitted]);

  useEffect(() => {
    setEmail(queryParams.get('email') || '');
    form.setFieldValue('sessionId', queryParams.get('session') || '');
  }, [form, queryParams]);

  return (
    <>
      {!isSubmitted ? (
        <Form<ResetPasswordFormFields>
          className="auth-form reset-password-form"
          initialValues={initialValues}
          onFinish={onFinished}
          onFieldsChange={onFieldsChange}
          form={form}
          autoComplete="new-password"
        >
          <Form.Item hidden name="sessionId">
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label={formatMessage({ id: 'global.tips.password' })}
            labelCol={{ offset: 0, span: 24 }}
            colon={false}
            required={false}
            tooltip={formatMessage({ id: 'global.tips.passwordFormat' })}
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'global.tips.enterPasswordMessage' })
              },
              {
                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&#]{8,}$/,
                message: formatMessage({ id: 'global.tips.invalidPasswordMessage' })
              }
            ]}
          >
            <Input.Password placeholder={formatMessage({ id: 'global.tips.password' })} />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label={formatMessage({ id: 'global.tips.confirmPassword' })}
            labelCol={{ offset: 0, span: 24 }}
            colon={false}
            required={false}
            tooltip={formatMessage({ id: 'global.tips.passwordFormat' })}
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'global.tips.enterConfirmPasswordMessage' })
              },
              ({ getFieldValue, isFieldTouched }) => ({
                validator(_, value) {
                  if (!isFieldTouched('confirmPassword') || !value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(formatMessage({ id: 'global.tips.invalidConfirmPasswordMessage' }));
                }
              })
            ]}
          >
            <Input.Password placeholder={formatMessage({ id: 'global.tips.confirmPassword' })} />
          </Form.Item>
          <Form.Item className="auth-form-footer">
            <Button htmlType="submit" size="large" type="primary" loading={isRequesting} block disabled={hasErrors}>
              <LocaleFormatter id="global.tips.resetPassword" />
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <div className="auth-result">
          <Typography.Paragraph>
            <LocaleFormatter id="global.tips.resetPasswordResultSuccess" />
          </Typography.Paragraph>
          <Typography.Paragraph>
            <a
              href={`mailto:${email}`}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              {email}
            </a>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <LocaleFormatter id="global.tips.resetPasswordRedirectLogin" values={{ countDown: countDown }} />
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Button type="primary" size="large" onClick={() => navigate('/auth/login')}>
              <LocaleFormatter id="global.tips.backToLogin" />
            </Button>
          </Typography.Paragraph>
        </div>
      )}
    </>
  );
};
