export const viVN_MaterialInformation = {
  'app.material.information.name': 'Tên nguyên liệu',
  'app.material.information.namePlaceholder': 'Nhà yến ABC',
  'app.material.information.enterNameMessage': 'Tên nguyên liệu không được bỏ trống',
  'app.material.information.type': 'Loại nguyên liệu',
  'app.material.information.typePlaceholder': 'Chọn loại nguyên liệu',
  'app.material.information.code': 'Mã lô',
  'app.material.information.selectTypeMessage': 'Chưa chọn loại nguyên liệu',
  'app.material.information.quantity': 'Số lượng',
  'app.material.information.quantityPlaceholder': '0',
  'app.material.information.invalidQuantityMessage': 'Số lượng phải lớn hơn hoặc bằng 0',
  'app.material.information.weight': 'Trọng lượng',
  'app.material.information.weightPlaceholder': '100',
  'app.material.information.enterWeightMessage': 'Trọng lượng không được bỏ trống',
  'app.material.information.invalidWeightMessage': 'Trọng lượng phải lớn hơn 0',
  'app.material.information.importedDate': 'Ngày nhập',
  'app.material.information.importedDatePlaceholder': 'dd/mm/yyyy',
  'app.material.information.selectImportedDateMessage': 'Ngày nhập không được bỏ trống',
  'app.material.information.expiredDate': 'Ngày hết hạn',
  'app.material.information.expiredDatePlaceholder': 'dd/mm/yyyy',
  'app.material.information.selectExpiredDateMessage': 'Ngày hết hạn không được bỏ trống',
  'app.material.information.invalidExpiredDateMessage': 'Ngày hết hạn phải sau ngày nhập',
  'app.material.information.supplier': 'Nguồn nhập',
  'app.material.information.supplierPlaceholder': 'Chọn nguồn nhập',
  'app.material.information.selectSupplierMessage': 'Chưa chọn nguồn nhập',
  'app.material.information.description': 'Mô tả ngắn',
  'app.material.information.descriptionPlaceholder': 'Nhập mô tả...',
  'app.material.information.importer': 'Người nhập',
  'app.material.information.qrStatus': 'Trạng thái QR',
  'app.material.information.qrStatusPlaceholder': 'Chọn trạng thái QR',
  'app.material.information.selectQrStatusMessage': 'Chưa chọn trạng thái QR',
  'app.material.information.qrStatus.true': 'Đã tạo',
  'app.material.information.qrStatus.false': 'Chưa tạo',
  'app.material.information.supplierIsDiseaseTestedMessage': 'Nguồn nhập đã được kiểm đinh trong khoảng thời gian {startDate} - {endDate}',
  'app.material.information.supplierIsNotDiseaseTestedMessage': 'Nguồn nhập chưa được kiểm định'
};
