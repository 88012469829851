import { ListUserFilterParams, ListUserParams, ListUserResult, User } from '@/interfaces/user';
import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createAsyncAction } from '../utils';
import { UserService } from '@/services/user.service';

interface State extends ListUserFilterParams {
  loading: boolean;
  users: User[];
  selectedUsers: User[];
  pageIndex: number;
  pageSize: number;
  totalItems: number;
  sortBy: string;
  sortDirection: 'ASC' | 'DESC';
}
const initialState: State = {
  loading: false,
  users: [],
  selectedUsers: [],
  pageIndex: 0,
  pageSize: 10,
  totalItems: 0,
  sortBy: 'id',
  sortDirection: 'DESC',
  keyword: null
};
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoadingUsers(state, { payload }: PayloadAction<boolean>) {
      state.loading = payload;
    },
    setUserState(state, { payload }: PayloadAction<ListUserResult & ListUserFilterParams>) {
      state.users = payload.items ?? [];
      state.pageIndex = payload.pageIndex ?? 0;
      state.pageSize = payload.pageSize ?? 0;
      state.totalItems = payload.totalItems ?? 0;
      state.keyword = payload.keyword ?? null;
    },
    addSelectedUser(state, { payload }: PayloadAction<User>) {
      state.selectedUsers = [...state.selectedUsers, payload];
    },
    removeSelectedUser(state, { payload }: PayloadAction<User>) {
      state.selectedUsers = state.selectedUsers.filter((item) => item.id !== payload.id);
    }
  }
});

export const { setLoadingUsers, setUserState, addSelectedUser, removeSelectedUser } = userSlice.actions;
export default userSlice.reducer;

export const getUsers = createAsyncAction((payload: ListUserParams) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoadingUsers(true));
      const res = await UserService.getListUser(payload);
      console.log(res.data, payload);
      dispatch(setUserState({ ...res.data, pageSize: payload.pageSize, keyword: payload.keyword }));
      dispatch(setLoadingUsers(false));
    } catch (error) {
      dispatch(
        setUserState({
          items: [],
          pageIndex: 0,
          totalItems: 0,
          pageSize: payload.pageSize,
          keyword: payload.keyword
        })
      );
      dispatch(setLoadingUsers(false));
      return Promise.reject(error);
    }
    return true;
  };
});
