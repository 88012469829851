import { CommonService as ICommonService } from '@/core/interfaces/service';
import { ApiService } from '@/core/services/api.service';
import {
  ListWardParams,
  ListWardResult,
  ListDistrictParams,
  ListDistrictResult,
  ListProvinceParams,
  ListProvinceResult,
  ListMaterialTypeParams,
  ListMaterialTypeResult,
  ListProductTypeParams,
  ListProductTypeResult,
  ListExpirationTypeParams,
  ListExpirationTypeResult,
  ListDiseaseTestingStatusResult,
  ListDiseaseTestingStatusParams,
  ListProductionShiftResult,
  ListProductionShiftParams,
  ListShipmentStatusParams,
  ListShipmentStatusResult,
  ListQrStatusParams,
  ListQrStatusResult,
  ListTraceabilityTypeParams,
  ListTraceabilityTypeResult
} from '@/interfaces/common';
export const CommonEndpoint = {
  Provinces: 'common/provinces',
  Districts: 'common/districts',
  Wards: 'common/wards',
  MaterialTypes: 'common/material-types',
  ProductTypes: 'common/product-types',
  ExpirationTypes: 'common/date-types',
  DiseaseTestingStatuses: 'common/disease-testing-statuses',
  ProductionShifts: 'common/shifts',
  ShipmentStatuses: 'common/manufacture-statuses',
  QrStatuses: 'common/qr-statuses',
  TraceabilityTypes: 'common/traceability-types'
};
class CommonService implements ICommonService<CommonService> {
  static instance: CommonService;

  static getInstance() {
    if (!CommonService.instance) {
      CommonService.instance = new CommonService();
    }
    return CommonService.instance;
  }

  constructor() {
    if (CommonService.instance) {
      throw new Error('Error: Instantiation failed: Use CommonService.getInstance() instead of new.');
    }
    CommonService.instance = this;
  }

  getProvinces(params: ListProvinceParams) {
    return ApiService.post<ListProvinceResult>(CommonEndpoint.Provinces, {
      ...params,
      pageSize: 9999
    });
  }

  getDistricts(params: ListDistrictParams) {
    return ApiService.post<ListDistrictResult>(CommonEndpoint.Districts, {
      ...params,
      pageSize: 9999
    });
  }

  getWards(params: ListWardParams) {
    return ApiService.post<ListWardResult>(CommonEndpoint.Wards, {
      ...params,
      pageSize: 9999
    });
  }

  getMaterialTypes(params: ListMaterialTypeParams) {
    return ApiService.post<ListMaterialTypeResult>(CommonEndpoint.MaterialTypes, params);
  }

  getProductTypes(params: ListProductTypeParams) {
    return ApiService.post<ListProductTypeResult>(CommonEndpoint.ProductTypes, params);
  }

  getExpirationTypes(params: ListExpirationTypeParams) {
    return ApiService.get<ListExpirationTypeResult>(CommonEndpoint.ExpirationTypes, params);
  }

  getDiseaseTestingStatuses(params: ListDiseaseTestingStatusParams) {
    return ApiService.get<ListDiseaseTestingStatusResult>(CommonEndpoint.DiseaseTestingStatuses, params);
  }

  getProductionShifts(params: ListProductionShiftParams) {
    return ApiService.post<ListProductionShiftResult>(CommonEndpoint.ProductionShifts, params);
  }

  getShipmentStatuses(params: ListShipmentStatusParams) {
    return ApiService.get<ListShipmentStatusResult>(CommonEndpoint.ShipmentStatuses, params);
  }

  getQrStatuses(params: ListQrStatusParams) {
    return ApiService.get<ListQrStatusResult>(CommonEndpoint.QrStatuses, params);
  }

  getTraceabilityTypes(params: ListTraceabilityTypeParams) {
    return ApiService.get<ListTraceabilityTypeResult>(CommonEndpoint.TraceabilityTypes, params);
  }
}

const instance = CommonService.getInstance();
export { instance as CommonService };
