export enum DISEASE_TESTING_STATUS {
  EXPIRED = 1,
  NOT_TESTED = 2,
  TESTED = 3
}

export enum DISEASE_TESTING_STATUS_CODE {
  EXPIRED = 'EXPIRED',
  NOT_TESTED = 'NOT_TESTED',
  TESTED = 'TESTED'
}
