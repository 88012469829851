export const viVN_QrCodeManagement = {
  'app.qrCode.management.title': 'Quản lý mã QR',
  'app.qrCode.management.detailTitle': 'Chi tiết mã QR {code}',
  'app.qrCode.management.deleteConfirmTitle': 'Xác nhận xóa',
  'app.qrCode.management.deleteConfirmMessage': 'Bạn có chắc chắn muốn xóa mã QR {code}?',
  'app.qrCode.management.deleteSuccessMessage': 'Xóa mã QR thành công',
  'app.qrCode.management.deleteMultipleConfirmMessage': 'Bạn có chắc chắn muốn xóa {count} mã QR đã chọn?',
  'app.qrCode.management.deleteMultipleSuccessMessage': 'Xóa {count} mã QR thành công',
  'app.qrCode.management.cannotDeleteTitle': 'Không thể xóa mã QR',
  'app.qrCode.management.cannotDeleteMessage': 'Bạn không thể xóa mã QR đang trong trạng thái kích hoạt. Cần hủy kích hoạt mã QR trước khi xóa.',
  'app.qrCode.management.selectedRecords': 'Đã chọn {count} kết quả',
  'app.qrCode.management.filterByTracebilityType': 'Lọc theo loại truy xuất',
  'app.qrCode.management.filterByStatus': 'Lọc theo trạng thái',
  'app.qrCode.management.reCreate': 'Tạo lại mã QR',
  'app.qrCode.management.print': 'In mã',
  'app.qrCode.management.printConfirmTitle': 'In mã QR',
  'app.qrCode.management.material.printConfirmMessage': 'Bạn có muốn in mã {code} này?',
  'app.qrCode.management.shipment.printConfirmMessage': 'Bạn có muốn in tất cả {count} mã hiện đang kích hoạt ở danh sách này của mã lô này?',
  'app.qrCode.management.printAll': 'In tất cả',
  'app.qrCode.management.printAllConfirmTitle': 'In tất cả mã QR',
  'app.qrCode.management.material.printAllConfirmMessage': 'ạn có muốn in mã {code} này?',
  'app.qrCode.management.shipment.printAllConfirmMessage': 'Bạn muốn in tất cả {count} mã hiện đang kích hoạt ở danh sách này ?',
  'app.qrCode.management.shipment.activateAll': 'Kích hoạt tất cả',
  'app.qrCode.management.shipment.activateAllConfirmTitle': 'Kích hoạt tất cả mã QR',
  'app.qrCode.management.shipment.activateAllConfirmMessage': 'Bạn có muốn kích hoạt lô sản phẩm {code}?',
  'app.qrCode.management.shipment.deactivateAll': 'Hủy kích hoạt tất cả',
  'app.qrCode.management.shipment.deactivateAllConfirmTitle': 'Hủy kích hoạt tất cả mã QR',
  'app.qrCode.management.shipment.deactivateAllConfirmMessage': 'Bạn có muốn hủy kích hoạt lô sản phẩm {code}?',
  'app.qrCode.management.material.activateAll': 'Kích hoạt tất cả',
  'app.qrCode.management.material.activateAllConfirmTitle': 'Kích hoạt mã QR {code}',
  'app.qrCode.management.material.activateAllConfirmMessage': 'Bạn có muốn kích hoạt lô nguyên liệu {code}?',
  'app.qrCode.management.activateSuccessMessage': 'Kích hoạt mã QR thành công',
  'app.qrCode.management.material.deactivateAll': 'Hủy kích hoạt tất cả',
  'app.qrCode.management.material.deactivateAllConfirmTitle': 'Hủy kích hoạt mã QR {code}',
  'app.qrCode.management.material.deactivateAllConfirmMessage': 'Bạn có muốn hủy kích hoạt lô nguyên liệu {code}?',
  'app.qrCode.management.deactivateSuccessMessage': 'Hủy kích hoạt mã QR thành công',
  'app.qrCode.management.reCreateConfirmTitle': 'Tạo lại mã QR',
  'app.qrCode.management.reCreateConfirmMessage': 'Bạn muốn tạo lại mã QR cho các sản phẩm đã xóa mã QR?',
  'app.qrCode.management.activateChildrenConfirmTitle': 'Kích hoạt mã QR',
  'app.qrCode.management.activateChildrenConfirmMessage': 'Bạn có muốn kích hoạt {code}?',
  'app.qrCode.management.deactivateChildrenConfirmTitle': 'Hủy kích hoạt mã QR',
  'app.qrCode.management.deactivateChildrenConfirmMessage': 'Bạn có muốn hủy kích hoạt {code}?',
  'app.qrCode.management.reCreateSuccessMessage': 'Tạo lại mã QR thành công',
  'app.qrCode.management.activateConfirmTitle': 'Kích hoạt mã QR',
  'app.qrCode.management.activateConfirmMessage': 'Bạn có muốn kích hoạt {count} mã QR đã chọn?',
  'app.qrCode.management.deactivateConfirmTitle': 'Hủy kích hoạt mã QR',
  'app.qrCode.management.deactivateConfirmMessage': 'Bạn có muốn hủy kích hoạt {count} mã QR đã chọn?',
  'app.qrCode.management.inputQuantityTitle': 'Nhập số lượng',
  'app.qrCode.management.inputQuantityMessage': 'Nhập số mã QR cần in',
  'app.qrCode.management.enterInputQuantityMessage': 'Số lượng mã QR không được bỏ trống',
  'app.qrCode.management.mininumInputQuantityMessage': 'Số lượng mã QR phải lớn hơn 0'
};
