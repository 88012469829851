import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createAsyncAction } from '../utils';
import { SortDirection } from '@/interfaces/general';
import { ListProductFilterParams, ListProductParams, ListProductResult, Product } from '@/interfaces/product';
import { ProductService } from '@/services/product.service';

interface State {
  loading: boolean;
  products: Product[];
  selectedProducts: Product[];
  pageIndex: number;
  pageSize: number;
  totalItems: number;
  sortBy: string;
  sortDirection: SortDirection;
  keyword: string | null;
  productTypeId: number | null;
}
const initialState: State = {
  loading: false,
  products: [],
  selectedProducts: [],
  pageIndex: 0,
  pageSize: 10,
  totalItems: 0,
  sortBy: 'id',
  sortDirection: 'DESC',
  keyword: null,
  productTypeId: null
};
const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setLoadingProducts(state, { payload }: PayloadAction<boolean>) {
      state.loading = payload;
    },
    setProductState(state, { payload }: PayloadAction<ListProductResult & ListProductFilterParams>) {
      state.products = payload.items ?? [];
      state.pageIndex = payload.pageIndex ?? 0;
      state.pageSize = payload.pageSize ?? 0;
      state.totalItems = payload.totalItems ?? 0;
      state.keyword = payload.keyword ?? null;
      state.productTypeId = payload.productTypeId ?? null;
      state.selectedProducts = state.selectedProducts.filter((u) => payload.items.find((s) => s.id === u.id));
    },
    addSelectedProduct(state, { payload }: PayloadAction<Product>) {
      state.selectedProducts = [...state.selectedProducts, payload];
    },
    removeSelectedProduct(state, { payload }: PayloadAction<Product>) {
      state.selectedProducts = state.selectedProducts.filter((item) => item.id !== payload.id);
    },
    setSelectedProducts(state, { payload }: PayloadAction<Product[]>) {
      state.selectedProducts = payload;
    }
  }
});

export const { setLoadingProducts, setProductState, addSelectedProduct, removeSelectedProduct, setSelectedProducts } = productSlice.actions;
export default productSlice.reducer;

export const getProducts = createAsyncAction((payload: ListProductParams) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoadingProducts(true));
      const res = await ProductService.listProduct(payload);
      dispatch(setProductState({ ...res.data, pageSize: payload.pageSize, keyword: payload.keyword, productTypeId: payload.productTypeId }));
      dispatch(setLoadingProducts(false));
      return true;
    } catch (error) {
      dispatch(
        setProductState({
          items: [],
          pageIndex: 0,
          totalItems: 0,
          pageSize: payload.pageSize,
          keyword: payload.keyword,
          productTypeId: payload.productTypeId
        })
      );
      dispatch(setLoadingProducts(false));
      return Promise.reject(error);
    }
  };
});
