import Avator from '@/assets/header/avatar.png';
import { ReactComponent as EnUsSvg } from '@/assets/header/en_US.svg';
import { ReactComponent as KoKRSvg } from '@/assets/header/ko_KR.svg';
import { ReactComponent as LanguageSvg } from '@/assets/header/language.svg';
import { ReactComponent as MoonSvg } from '@/assets/header/moon.svg';
import { ReactComponent as SunSvg } from '@/assets/header/sun.svg';
import { ReactComponent as ViVNSvg } from '@/assets/header/vi_VN.svg';
import { ReactComponent as ZhCnSvg } from '@/assets/header/zh_CN.svg';
import logo from '@/assets/logo/logo_yen-800x800.png';
import { LocaleFormatter, useLocale } from '@/locales';
import { setGlobalState } from '@/stores/reducer/global.reducer';
import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';
import { Dropdown, Layout, Tooltip, theme as antTheme } from 'antd';
import { FC, createElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HeaderNoticeComponent } from '../notice';
import { logoutAsync } from '@/stores/reducer/auth.reducer';

const { Header } = Layout;

interface HeaderProps {
  collapsed: boolean;
  toggle: () => void;
}

type Action = 'userInfo' | 'userSetting' | 'logout';

export const HeaderComponent: FC<HeaderProps> = ({ collapsed, toggle }) => {
  const { locale, device } = useSelector((state) => state.global);
  const { logged } = useSelector((state) => state.auth);
  const { theme } = useSelector((state) => state.global);
  const navigate = useNavigate();
  const token = antTheme.useToken();
  const dispatch = useDispatch();
  const { formatMessage } = useLocale();

  const onActionClick = async (action: Action) => {
    switch (action) {
      case 'userInfo':
        return;
      case 'userSetting':
        return;
      case 'logout':
        const res = Boolean(await dispatch(logoutAsync()));

        res && navigate('/auth/login');

        return;
    }
  };

  const toLogin = () => {
    navigate('/auth/login');
  };

  const selectLocale = ({ key }: { key: any }) => {
    dispatch(setGlobalState({ locale: key }));
    localStorage.setItem('locale', key);
  };

  const onChangeTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';

    localStorage.setItem('theme', newTheme);
    dispatch(
      setGlobalState({
        theme: newTheme
      })
    );
  };

  return (
    <Header className="main-header bg-2" style={{ backgroundColor: token.token.colorBgContainer }}>
      {device !== 'MOBILE' && (
        <div className="logo" style={{ width: collapsed ? 80 : 240 }}>
          <img src={logo} alt="" style={{ marginRight: collapsed ? '2px' : '20px' }} />
        </div>
      )}
      <div className="main-header-main">
        <div onClick={toggle}>
          <span id="sidebar-trigger">{collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}</span>
        </div>
        <div className="actions">
          <Tooltip
            title={formatMessage({
              id: theme === 'dark' ? 'global.tips.theme.lightTooltip' : 'global.tips.theme.darkTooltip'
            })}
          >
            <span>
              {createElement(theme === 'dark' ? SunSvg : MoonSvg, {
                onClick: onChangeTheme
              })}
            </span>
          </Tooltip>
          {/* <HeaderNoticeComponent />
          <Dropdown
            menu={{
              onClick: (info) => selectLocale(info),
              items: [
                {
                  key: 'zh_CN',
                  icon: <ZhCnSvg />,
                  disabled: locale === 'zh_CN',
                  label: '简体中文'
                },
                {
                  key: 'en_US',
                  icon: <EnUsSvg />,
                  disabled: locale === 'en_US',
                  label: 'English'
                },
                {
                  key: 'ko_KR',
                  icon: <KoKRSvg />,
                  disabled: locale === 'ko_KR',
                  label: 'Korea'
                },
                {
                  key: 'vi_VN',
                  icon: <ViVNSvg />,
                  disabled: locale === 'vi_VN',
                  label: 'Việt Nam'
                }
              ]
            }}
          >
            <span>
              <LanguageSvg id="language-change" />
            </span>
          </Dropdown> */}

          {logged ? (
            <Dropdown
              menu={{
                items: [
                  {
                    key: '1',
                    icon: <UserOutlined />,
                    onClick: () => navigate('/user/profile'),
                    label: (
                      <span>
                        <LocaleFormatter id="header.avator.profile" />
                      </span>
                    )
                  },
                  {
                    key: '2',
                    icon: <LogoutOutlined />,
                    onClick: () => onActionClick('logout'),
                    label: (
                      <span>
                        <LocaleFormatter id="header.avator.logout" />
                      </span>
                    )
                  }
                ]
              }}
            >
              <span className="user-action">
                <img src={Avator} className="user-avator" alt="avator" />
              </span>
            </Dropdown>
          ) : (
            <span style={{ cursor: 'pointer' }} onClick={toLogin}>
              {formatMessage({ id: 'global.tips.login' })}
            </span>
          )}
        </div>
      </div>
    </Header>
  );
};
