import { CommonService } from '@/core/interfaces/service';
import { ApiService } from '@/core/services/api.service';
import {
  CreateMaterialParams,
  CreateMaterialResult,
  DeleteMaterialParams,
  DeleteMultipleMaterialParams,
  DetailMaterialParams,
  DetailMaterialResult,
  ListMaterialParams,
  ListMaterialResult,
  UpdateMaterialParams,
  UpdateMaterialResult
} from '@/interfaces/material';

export const MaterialMaterialEndpoint = {
  List: '/supplier-materials/search',
  Create: '/supplier-materials/add',
  Update: '/supplier-materials/edit',
  Delete: '/supplier-materials/delete',
  Detail: '/supplier-materials/detail',
  DeleteMultiple: '/supplier-materials/delete-multiple'
};

class MaterialService implements CommonService {
  static instance: MaterialService;

  constructor() {
    if (MaterialService.instance) {
      throw new Error('Error: Instantiation failed: Use MaterialService.getInstance() instead of new.');
    }
    MaterialService.instance = this;
  }

  static getInstance() {
    if (!MaterialService.instance) {
      MaterialService.instance = new MaterialService();
    }
    return MaterialService.instance;
  }

  createMaterial(params: CreateMaterialParams) {
    return ApiService.post<CreateMaterialResult>(MaterialMaterialEndpoint.Create, params);
  }

  listMaterial(params: ListMaterialParams) {
    return ApiService.post<ListMaterialResult>(MaterialMaterialEndpoint.List, params);
  }

  deleteMaterial(params: DeleteMaterialParams) {
    return ApiService.post(MaterialMaterialEndpoint.Delete, params);
  }

  detailMaterial(params: DetailMaterialParams) {
    return ApiService.post<DetailMaterialResult>(MaterialMaterialEndpoint.Detail, params);
  }

  updateMaterial(params: UpdateMaterialParams) {
    return ApiService.post<UpdateMaterialResult>(MaterialMaterialEndpoint.Update, params);
  }

  deleteMultipleMaterials(params: DeleteMultipleMaterialParams) {
    return ApiService.post<DeleteMultipleMaterialParams>(MaterialMaterialEndpoint.DeleteMultiple, params);
  }
}

const instance = MaterialService.getInstance();

export { instance as MaterialService };
