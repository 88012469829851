import { ForgotPasswordForm } from '@/components/auth/forgot-password-form';

const ForgotPasswordPage = () => {
  return (
    <>
      <ForgotPasswordForm />
    </>
  );
};
export default ForgotPasswordPage;
