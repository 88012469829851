export const viVN_ShipmentInformation = {
  'app.shipment.information.generalInformation': 'Thông tin chung',
  'app.shipment.information.productInformation': 'Sản phẩm đầu ra',
  'app.shipment.information.materialInformation': 'Nguyên liệu đầu vào',
  'app.shipment.information.qrCodeId': 'QR code ID',
  'app.shipment.information.code': 'Mã lô hàng',
  'app.shipment.information.name': 'Tên lô hàng',
  'app.shipment.information.enterNameMessage': 'Tên lô hàng không được bỏ trống',
  'app.shipment.information.productName': 'Tên sản phẩm',
  'app.shipment.information.materialType': 'Loại nguyên liệu',
  'app.shipment.information.materialTypePlaceholder': 'Chọn loại nguyên liệu',
  'app.shipment.information.selectMaterialTypeMessage': 'Chưa chọn loại nguyên liệu',
  'app.shipment.information.materialShipment': 'Lô nguyên liệu đầu vào',
  'app.shipment.information.materialShipmentPlaceholder': 'Chọn lô nguyên liệu đầu vào',
  'app.shipment.information.selectMaterialShipmentMessage': 'Chưa chọn lô nguyên liệu đầu vào',
  'app.shipment.information.supplier': 'Nguồn nhập',
  'app.shipment.information.supplierPlaceholder': 'Chọn nguồn nhập',
  'app.shipment.information.selectSupplierMessage': 'Chưa chọn nguồn nhập',
  'app.shipment.information.dateOfManufacture': 'Ngày sản xuất',
  'app.shipment.information.selectDateOfManufactureMessage': 'Chưa chọn ngày sản xuất',
  'app.shipment.information.dateOfManufacturePlaceholder': 'dd/mm/yyyy',
  'app.shipment.information.expirationDate': 'Ngày hết hạn',
  'app.shipment.information.selectExpirationDateMessage': 'Chưa chọn ngày hết hạn',
  'app.shipment.information.expirationDatePlaceholder': 'dd/mm/yyyy',
  'app.shipment.information.compareExpirationDateMessage': 'Ngày hết hạn phải sau ngày sản xuất',
  'app.shipment.information.productionShift': 'Ca sản xuất',
  'app.shipment.information.productionShiftPlaceholder': 'Chọn ca sản xuất',
  'app.shipment.information.selectProductionShiftMessage': 'Chưa chọn ca sản xuất',
  'app.shipment.information.product': 'Sản phẩm đầu ra',
  'app.shipment.information.productPlaceholder': 'Chọn sản phẩm đầu ra',
  'app.shipment.information.selectProductMessage': 'Chưa chọn sản phẩm đầu ra',
  'app.shipment.information.batchOfProduct': 'Mẻ sản xuất trong ngày',
  'app.shipment.information.batchOfProductPlaceholder': '100',
  'app.shipment.information.enterBatchOfProductMessage': 'Mẻ sản xuất trong ngày không được bỏ trống',
  'app.shipment.information.minBatchOfProductMessage': 'Mẻ sản xuất trong ngày phải lớn hơn 0',
  'app.shipment.information.productQuantity': 'Số lượng sản phẩm',
  'app.shipment.information.productQuantityPlaceholder': '100',
  'app.shipment.information.enterProductQuantityMessage': 'Số lượng sản phẩm không được bỏ trống',
  'app.shipment.information.minProductQuantityMessage': 'Số lượng sản phẩm phải lớn hơn 0',
  'app.shipment.information.status': 'Trạng thái lô hàng',
  'app.shipment.information.statusPlaceholder': 'Chọn trạng thái lô hàng',
  'app.shipment.information.selectStatusMessage': 'Chưa chọn trạng thái lô hàng',
  'app.shipment.information.statusTooltip':
    'Khi chuyển đổi trạng thái "Đã khởi tạo QR code" sẽ không thể chỉnh sửa các thông tin trong lô hàng sản xuất',
  'app.shipment.information.qrStatus': 'Trạng thái QR',
  'app.shipment.information.materialWeight': 'Trọng lượng nguyên liệu',
  'app.shipment.information.materialWeightPlaceholder': '100',
  'app.shipment.information.enterMaterialWeightMessage': 'Trọng lượng nguyên liệu không được bỏ trống',
  'app.shipment.information.minMaterialWeightMessage': 'Trọng lượng nguyên liệu phải lớn hơn 0',
  'app.shipment.information.materialWeightUsedMessage': 'Trọng lượng nguyên liệu của lô nguyên liệu không đủ đáp ứng!',
  'app.shipment.information.materialQuantity': 'Số lượng nguyên liệu',
  'app.shipment.information.materialQuantityPlaceholder': '100',
  'app.shipment.information.enterMaterialQuantityMessage': 'Số lượng nguyên liệu không được bỏ trống',
  'app.shipment.information.minMaterialQuantityMessage': 'Số lượng nguyên liệu phải lớn hơn 0',
  'app.shipment.information.description': 'Mô tả',
  'app.shipment.information.productType': 'Loại sản phẩm đầu ra',
  'app.shipment.information.productTypePlaceholder': 'Chọn loại sản phẩm đầu ra',
  'app.shipment.information.selectProductTypeMessage': 'Chưa chọn loại sản phẩm đầu ra',
  'app.shipment.information.pieceOfNest': 'Tai yến',
  'app.shipment.information.productWeight': 'Trọng lượng sản phẩm',
  'app.shipment.information.productWeightPlaceholder': '100',
  'app.shipment.information.enterProductWeightMessage': 'Trọng lượng sản phẩm không được bỏ trống',
  'app.shipment.information.minProductWeightMessage': 'Trọng lượng sản phẩm phải lớn hơn 0'
};
