export const viVN_ProductInformation = {
  'app.product.information.info': 'Thông tin sản phẩm',
  'app.product.information.name': 'Tên sản phẩm',
  'app.product.information.namePlaceholder': 'Yến lọ ABC',
  'app.product.information.enterNameMessage': 'Tên sản phẩm không được bỏ trống',
  'app.product.information.code': 'Mã sản phẩm',
  'app.product.information.codePlaceholder': 'SP001',
  'app.product.information.enterCodeMessage': 'Mã sản phẩm không được bỏ trống',
  'app.product.information.invalidCodeMessage': 'Mã sản phẩm không được bao gồm ký tự đặc biệt và khoảng trắng',
  'app.product.information.type': 'Loại sản phẩm',
  'app.product.information.typePlaceholder': 'Chọn loại sản phẩm',
  'app.product.information.selectTypeMessage': 'Chưa chọn loại sản phẩm',
  'app.product.information.description': 'Mô tả ngắn',
  'app.product.information.descriptionPlaceholder': 'Nhập mô tả...',
  'app.product.information.image': 'Ảnh sản phẩm',
  'app.product.information.imagePlaceholder': 'Chọn ảnh sản phẩm',
  'app.product.information.selectImageMessage': 'Chưa chọn ảnh sản phẩm',
  'app.product.information.imageSizeMessage': 'Kích thước ảnh phải nhỏ hơn 2MB',
  'app.product.information.imageTypeMessage': 'Ảnh phải có định dạng JPG, PNG hoặc JPEG',
  'app.product.information.expiration': 'Hạn sử dụng',
  'app.product.information.expirationPlaceholder': '30',
  'app.product.information.enterExpirationMessage': 'Hạn sử dụng không được bỏ trống'
};
