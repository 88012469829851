import { CommonService } from '@/core/interfaces/service';
import { ApiService } from '@/core/services/api.service';
import {
  CreateSupplierParams,
  DeleteMultipleSupplierParams,
  DeleteSupplierParams,
  DetailSupplierParams,
  DetailSupplierResult,
  ListSupplierParams,
  ListSupplierResult,
  UpdateSupplierParams,
  UpdateSupplierResult
} from '@/interfaces/supplier';

export const SupplierEndpoint = {
  List: '/suppliers/search',
  Create: '/suppliers/add',
  Update: '/suppliers/edit',
  Delete: '/suppliers/delete',
  Detail: '/suppliers/detail',
  DeleteMultiple: '/suppliers/delete-multiple'
};

class SupplierService implements CommonService {
  static instance: SupplierService;

  constructor() {
    if (SupplierService.instance) {
      throw new Error('Error: Instantiation failed: Use SupplierService.getInstance() instead of new.');
    }
    SupplierService.instance = this;
  }

  static getInstance() {
    if (!SupplierService.instance) {
      SupplierService.instance = new SupplierService();
    }
    return SupplierService.instance;
  }

  createSupplier(params: CreateSupplierParams) {
    return ApiService.post(SupplierEndpoint.Create, params);
  }

  listSupplier(params: ListSupplierParams) {
    return ApiService.post<ListSupplierResult>(SupplierEndpoint.List, params);
  }

  deleteSupplier(params: DeleteSupplierParams) {
    return ApiService.post(SupplierEndpoint.Delete, params);
  }

  detailSupplier(params: DetailSupplierParams) {
    return ApiService.post<DetailSupplierResult>(SupplierEndpoint.Detail, params);
  }

  updateSupplier(params: UpdateSupplierParams) {
    return ApiService.post<UpdateSupplierResult>(SupplierEndpoint.Update, params);
  }

  deleteMultipleSuppliers(params: DeleteMultipleSupplierParams) {
    return ApiService.post<DeleteMultipleSupplierParams>(SupplierEndpoint.DeleteMultiple, params);
  }
}

const instance = SupplierService.getInstance();

export { instance as SupplierService };
