import { History } from '@/routes';
import { AxiosError, AxiosResponse } from 'axios';

export function ResponseInterceptorResolve(response: AxiosResponse) {
  if (response.data && response.config.responseType !== 'blob' && response.config.responseType !== 'arraybuffer' && response.data.code !== 0) {
    return Promise.reject(response.data?.message);
  }
  return response.data;
}

export function ResponseInterceptorReject(error: AxiosError) {
  if (error.response?.status === 401) {
    History.push('/auth/login');
    localStorage.removeItem('t');
    return Promise.reject(error.response?.statusText);
  }
  if (error.response?.data?.message) {
    return Promise.reject(error.response.data.message);
  }
  return Promise.reject(error.message);
}
