import ReactDOM from 'react-dom';
import './styles/index.less';
import store from './stores';
import { Provider } from 'react-redux';
import App from './App';
import './mocks';
import packageJson from '../package.json';

console.log(`Version: v${packageJson.version}`);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
