import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createAsyncAction } from '../utils';
import { ListSupplierFilterParams, ListSupplierParams, ListSupplierResult, Supplier } from '@/interfaces/supplier';
import { SupplierService } from '@/services/supplier.service';
import { SortDirection } from '@/interfaces/general';

interface State extends ListSupplierFilterParams {
  loading: boolean;
  suppliers: Supplier[];
  selectedSuppliers: Supplier[];
  pageIndex: number;
  pageSize: number;
  totalItems: number;
  sortBy: string;
  sortDirection: SortDirection;
}
const initialState: State = {
  loading: false,
  suppliers: [],
  selectedSuppliers: [],
  pageIndex: 0,
  pageSize: 10,
  totalItems: 0,
  sortBy: 'id',
  sortDirection: 'DESC',
  keyword: null,
  materialId: null
};
const supplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    setLoadingSuppliers(state, { payload }: PayloadAction<boolean>) {
      state.loading = payload;
    },
    setSupplierState(state, { payload }: PayloadAction<ListSupplierResult & ListSupplierFilterParams>) {
      state.suppliers = payload.items ?? [];
      state.pageIndex = payload.pageIndex ?? 0;
      state.pageSize = payload.pageSize ?? 0;
      state.totalItems = payload.totalItems ?? 0;
      state.keyword = payload.keyword ?? null;
      state.materialId = payload.materialId ?? null;
      state.selectedSuppliers = state.selectedSuppliers.filter((u) => payload.items.find((s) => s.id === u.id));
    },
    addSelectedSupplier(state, { payload }: PayloadAction<Supplier>) {
      state.selectedSuppliers = [...state.selectedSuppliers, payload];
    },
    removeSelectedSupplier(state, { payload }: PayloadAction<Supplier>) {
      state.selectedSuppliers = state.selectedSuppliers.filter((item) => item.id !== payload.id);
    },
    setSelectedSuppliers(state, { payload }: PayloadAction<Supplier[]>) {
      state.selectedSuppliers = payload;
    }
  }
});

export const { setLoadingSuppliers, setSupplierState, addSelectedSupplier, removeSelectedSupplier, setSelectedSuppliers } = supplierSlice.actions;
export default supplierSlice.reducer;

export const getSuppliers = createAsyncAction((payload: ListSupplierParams) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoadingSuppliers(true));
      const res = await SupplierService.listSupplier(payload);
      dispatch(setSupplierState({ ...res.data, pageSize: payload.pageSize, keyword: payload.keyword, materialId: payload.materialId }));
      dispatch(setLoadingSuppliers(false));
      return true;
    } catch (error) {
      dispatch(
        setSupplierState({
          items: [],
          pageIndex: 0,
          totalItems: 0,
          pageSize: payload.pageSize,
          keyword: payload.keyword,
          materialId: payload.materialId
        })
      );
      dispatch(setLoadingSuppliers(false));
      return Promise.reject(error);
    }
  };
});
