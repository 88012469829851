export const viVN_MaterialManagement = {
  'app.material.management.title': 'Nhập nguyên liệu đầu vào',
  'app.material.management.createTitle': 'Thêm nguyên liệu đầu vào',
  'app.material.management.createSuccessMessage': 'Thêm nguyên liệu đầu vào thành công',
  'app.material.management.qrCodeCreateSuccessMessage': 'Mã QR code cho mã lô {code} đã được tạo thành công',
  'app.material.management.updateTitle': 'Chỉnh sửa nguyên liệu đầu vào {code}',
  'app.material.management.updateSuccessMessage': 'Lưu thông tin thành công',
  'app.material.management.deleteConfirmModalTitle': 'Xác nhận xóa',
  'app.material.management.deleteConfirmModalContent': 'Bạn có chắc chắn muốn xóa lô nguyên liệu {code}?',
  'app.material.management.deleteMultipleConfirmModalContent': 'Bạn có chắc muốn xóa {count} lô nguyên liệu đầu vào đã chọn?',
  'app.material.management.deleteInUsedConfirmModalContent': 'Lô nguyên liệu đầu vào {code} đang được sử dụng, bạn có chắc muốn xóa?',
  'app.material.management.deleteInUsedMultipleConfirmModalContent':
    'Một số lô nguyên liệu đang được sử dụng, bạn có chắc muốn xóa {count} lô nguyên liệu đã chọn?',
  'app.material.management.deleteSuccessMessage': 'Xóa nguyên liệu đầu vào {code} thành công',
  'app.material.management.deleteMultipleSuccess': 'Xóa {count} lô nguyên liệu đầu vào thành công',
  'app.material.management.qrCodeModalTitle': 'Mã QR lô nguyên liệu {code}',
  'app.material.management.printQrCode': 'In mã',
  'app.material.management.selectedRecords': 'Đã chọn {count} lô nguyên liệu',
  'app.material.management.filterByType': 'Lọc theo loại nguyên liệu',
  'app.material.management.filterBySupplier': 'Lọc theo nguồn nhập',
  'app.material.management.filterByQrStatus': 'Lọc theo trạng thái QR',
  'app.material.management.confirmCreateQRMessage': 'Bạn có chắc chắn muốn tạo mã QR cho lô nguyên liệu {code}?',
  'app.material.management.cannotDeleteMessage': 'Không thể xóa vì lô nguyên liệu đầu vào {code} đang được sử dụng trong sản xuất!',
  'app.material.management.cannotDeleteMultipleMessage': 'Không thể xóa vì một số lô nguyên liệu đang được sử dụng trong sản xuất!'
};
