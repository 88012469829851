import { AxiosRequestConfig } from 'axios';

const PUBLIC_API = ['auth/login', 'auth/forgot-password'];

export function RequestInterceptorResolve(config: AxiosRequestConfig) {
  if (config.url && !PUBLIC_API.includes(config.url)) {
    const token = localStorage.getItem('t');
    if (token) {
      (config.headers as any)['Authorization'] = `Bearer ${token}`;
    }
  }

  return config;
}

export function RequestInterceptorReject(error: any) {
  return Promise.reject(error);
}
