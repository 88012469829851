import { useNotification } from '@/hooks';
import './index.scss';
import { LoginParams } from '@/interfaces/auth';
import { LocaleFormatter, useLocale } from '@/locales';
import { loginAsync } from '@/stores/reducer/auth.reducer';
import { formatSearch } from '@/utils/formatSearch';
import { Form, Input, Checkbox, Button, Row } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';

const initialValues: LoginParams = {
  email: '',
  password: ''
  // remember: false
};

export function LoginForm() {
  const [isRequesting, setIsRequesting] = useState(false);

  const { formatMessage } = useLocale();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const notify = useNotification();
  const [hasErrors, setHasErrors] = useState(true);
  const [form] = Form.useForm<LoginParams>();

  const onFieldsChange = () => {
    const errors = form.getFieldsError();
    const hasError = errors.some((item) => item.errors.length > 0);
    const touched = form.isFieldsTouched(['email', 'password'], true);
    console.log(errors, touched);
    setHasErrors(hasError || !touched);
  };

  const onFinished = async (form: LoginParams) => {
    setIsRequesting(true);
    try {
      const res = await dispatch(loginAsync(form));
      if (!!res) {
        const search = formatSearch(location.search);
        const from = search.from || { pathname: '/' };
        notify.success(formatMessage({ id: 'global.tips.success' }), formatMessage({ id: 'global.tips.loginSuccess' }));
        navigate(from);
      }
    } catch (error) {
      notify.error(formatMessage({ id: 'global.tips.error' }), error);
      setIsRequesting(false);
    }
  };

  return (
    <>
      <Form<LoginParams>
        form={form}
        onFieldsChange={onFieldsChange}
        onFinish={onFinished}
        className="auth-form login-form"
        initialValues={initialValues}
        autoComplete="new-password"
      >
        <input style={{ display: 'none' }} type="text" name="fakeusernameremembered" />
        <input style={{ display: 'none' }} type="password" name="fakepasswordremembered" />
        <Form.Item
          label={formatMessage({ id: 'global.tips.email' })}
          name="email"
          required={false}
          colon={false}
          labelCol={{ offset: 0, span: 24 }}
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'global.tips.enterEmailMessage'
              })
            },
            {
              type: 'email',
              message: formatMessage({
                id: 'global.tips.invalidEmailMessage'
              })
            }
          ]}
        >
          <Input
            placeholder={formatMessage({
              id: 'global.tips.email'
            })}
          />
        </Form.Item>
        <Form.Item
          name="password"
          label={formatMessage({ id: 'global.tips.password' })}
          required={false}
          colon={false}
          labelCol={{ offset: 0, span: 24 }}
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'global.tips.enterPasswordMessage'
              })
            },
            {
              pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&#]{8,}$/,
              message: formatMessage({ id: 'global.tips.invalidPasswordMessage' })
            }
          ]}
        >
          <Input.Password
            type="password"
            placeholder={formatMessage({
              id: 'global.tips.password'
            })}
          />
        </Form.Item>
        <Form.Item name="remember" valuePropName="checked" style={{ textAlign: 'right' }}>
          {/* <Row justify="end"> */}
          {/* <Checkbox>
              <LocaleFormatter id="global.tips.rememberUser" />
            </Checkbox> */}
          <Link to="/auth/forgot-password" className="auth-form-link">
            <LocaleFormatter id="global.tips.forgotPassword" />
          </Link>
          {/* </Row> */}
        </Form.Item>
        <Form.Item className="login-form-footer">
          <Button loading={isRequesting} htmlType="submit" type="primary" className="login-form_button" size="large" disabled={hasErrors}>
            <LocaleFormatter id="global.tips.login" />
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
