import { ProfileParams, ProfileResult } from '@/interfaces/auth';
import { intercepter, mock } from '../config';

mock.mock('/auth/profile', 'get', (config: any) => {
  return intercepter<ProfileResult>({
    id: 1,
    name: 'Nguyễn Văn A',
    phone: '0123456789',
    email: 'amitgroup@gmail.com',
    role: 'Admin',
    authorities: ['FULL_PERMISSONS']
  });
});

mock.mock('/auth/profile', 'post', (config: any) => {
  const body: ProfileParams = JSON.parse(config.body || {});

  return intercepter<ProfileResult>({
    id: 1,
    email: 'amitgroup@gmail.com',
    role: 'Admin',
    authorities: ['FULL_PERMISSONS'],
    ...body
  });
});
