import { CommonService } from '@/core/interfaces/service';
import { ApiService } from '@/core/services/api.service';
import { ForgotPasswordParams, LoginParams, LoginResult, LogoutParams, LogoutResult, ProfileParams, ProfileResult } from '@/interfaces/auth';
import { ResetPasswordParams, ResetPasswordResult } from '@/interfaces/auth/reset-password.interface';

export const AuthEndpoint = {
  Login: 'auth/sign-in',
  Logout: 'auth/sign-out',
  Profile: 'auth/profile',
  UpdateProfile: 'auth/update-profile',
  ForgotPassword: 'auth/forgot-password',
  ResetPassword: 'auth/forgot-password/complete'
};

class AuthService implements CommonService<any> {
  static instance: AuthService;

  static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }

  constructor() {
    if (AuthService.instance) {
      throw new Error('Error: Instantiation failed: Use AuthService.getInstance() instead of new.');
    }
    AuthService.instance = this;
  }

  login(data: LoginParams) {
    return ApiService.post<LoginResult>(AuthEndpoint.Login, data);
  }

  logout() {
    return ApiService.post<LogoutResult>(AuthEndpoint.Logout, {});
  }

  forgotPassword(data: ForgotPasswordParams) {
    return ApiService.post(AuthEndpoint.ForgotPassword, data);
  }

  resetPassword(data: ResetPasswordParams) {
    return ApiService.post<ResetPasswordResult>(AuthEndpoint.ResetPassword, data);
  }

  profile() {
    return ApiService.get<ProfileResult>(AuthEndpoint.Profile);
  }

  updateProfile(data: ProfileParams) {
    return ApiService.post<ProfileResult>(AuthEndpoint.UpdateProfile, data);
  }
}

const instance = AuthService.getInstance();

export { instance as AuthService };
