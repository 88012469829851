import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createAsyncAction } from '../utils';
import { SortDirection } from '@/interfaces/general';
import { ListShipmentFilterParams, ListShipmentParams, ListShipmentResult, Shipment } from '@/interfaces/shipment';
import { ShipmentService } from '@/services/shipment.service';

type State = ListShipmentFilterParams & {
  loading: boolean;
  shipments: Shipment[];
  selectedShipments: Shipment[];
  pageIndex: number;
  pageSize: number;
  totalItems: number;
  sortBy: string;
  sortDirection: SortDirection;
};
const initialState: State = {
  loading: false,
  shipments: [],
  selectedShipments: [],
  pageIndex: 0,
  pageSize: 10,
  totalItems: 0,
  sortBy: 'id',
  sortDirection: 'DESC',
  keyword: null,
  shiftId: null,
  manufactureStatus: null,
  qrStatus: null
};
const shipmentSlice = createSlice({
  name: 'shipment',
  initialState,
  reducers: {
    setLoadingShipments(state, { payload }: PayloadAction<boolean>) {
      state.loading = payload;
    },
    setShipmentState(state, { payload }: PayloadAction<ListShipmentResult & ListShipmentFilterParams>) {
      state.shipments = payload.items ?? [];
      state.pageIndex = payload.pageIndex ?? 0;
      state.pageSize = payload.pageSize ?? 0;
      state.totalItems = payload.totalItems ?? 0;
      state.keyword = payload.keyword ?? null;
      state.shiftId = payload.shiftId ?? null;
      state.manufactureStatus = payload.manufactureStatus ?? null;
      state.qrStatus = payload.qrStatus ?? null;
      state.selectedShipments = state.selectedShipments.filter((u) => payload.items.find((s) => s.id === u.id));
    },
    addSelectedShipment(state, { payload }: PayloadAction<Shipment>) {
      state.selectedShipments = [...state.selectedShipments, payload];
    },
    removeSelectedShipment(state, { payload }: PayloadAction<Shipment>) {
      state.selectedShipments = state.selectedShipments.filter((item) => item.id !== payload.id);
    },
    setSelectedShipments(state, { payload }: PayloadAction<Shipment[]>) {
      state.selectedShipments = payload;
    }
  }
});

export const { setLoadingShipments, setShipmentState, addSelectedShipment, removeSelectedShipment, setSelectedShipments } = shipmentSlice.actions;
export default shipmentSlice.reducer;

export const getShipments = createAsyncAction((payload: ListShipmentParams) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoadingShipments(true));
      const res = await ShipmentService.listShipment(payload);
      dispatch(
        setShipmentState({
          ...res.data,
          pageSize: payload.pageSize,
          keyword: payload.keyword,
          shiftId: payload.shiftId,
          manufactureStatus: payload.manufactureStatus,
          qrStatus: payload.qrStatus
        })
      );
      dispatch(setLoadingShipments(false));
      return true;
    } catch (error) {
      dispatch(
        setShipmentState({
          items: [],
          pageIndex: 0,
          totalItems: 0,
          pageSize: payload.pageSize,
          keyword: payload.keyword,
          shiftId: payload.shiftId,
          manufactureStatus: payload.manufactureStatus,
          qrStatus: payload.qrStatus
        })
      );
      dispatch(setLoadingShipments(false));
      return Promise.reject(error);
    }
  };
});
