export const viVN_TraceabilityResult = {
  'app.traceability.inputCode': 'Nhập mã truy xuất...',
  'app.traceability.notfound': 'Không tìm thấy dữ liệu này',
  'app.traceability.code': 'Mã truy xuất: {code}',
  'app.traceability.information': 'Thông tin truy xuất',
  'app.traceability.product.information': 'Thông tin sản phẩm',
  'app.traceability.material.information': 'Thông tin nguyên liệu',
  'app.traceability.supplier.information': 'Thông tin nguồn nhập',
  'app.traceability.product.name': 'Tên sản phẩm',
  'app.traceability.product.type': 'Loại sản phẩm',
  'app.traceability.product.shipmentCode': 'Mã lô sản xuất',
  'app.traceability.product.description': 'Mô tả sản phẩm',
  'app.traceability.product.description.value': `- Nguồn nguyên liệu tổ yến: Việt Nam
  - Thời hạn sử dụng: 24 tháng
  - Điều kiện bảo quản: nơi khô ráo, thoáng mát tránh ánh nắng mặt trời trực tiếp
  - Mô tả sản phẩm: Công ty chúng tôi là doanh nghiệp chế biến sản phẩm yến sào trong nước được chứng nhận phù hợp với “quy chuẩn kỹ thuật vệ sinh dành cho doanh nghiệp chế biến sản phẩm yến sào” và đã thiết lập khả năng truy xuất nguồn gốc an toàn thực phẩm này phù hợp với yêu cầu của luật an toàn vệ sinh thực phẩm Trung Quốc. 
  
  Mỗi sản phẩm yến sào do công ty chúng tôi sản xuất và chế biến đều được đóng gói riêng sau khi sấy khô và khử trùng. Tất cả các chỉ tiêu đều tuân thủ yêu cầu kiểm nghiệm quốc gia. 
  `,
  'app.traceability.company.information': 'Thông tin nhà sản xuất',
  'app.traceability.company.name': 'Tên nhà sản xuất',
  'app.traceability.company.vietnamRegistration': 'GPKD',
  'app.traceability.company.chinaRegistration': 'Số đăng ký Trung Quốc',
  'app.traceability.company.address': 'Địa chỉ nhà sản xuất',
  'app.traceability.company.name.value': 'Công Ty TNHH MT NEST',
  'app.traceability.company.vietnamRegistration.value': '4201954317',
  'app.traceability.company.chinaRegistration.value': 'CVNM03012401240008',
  'app.traceability.company.address.value': 'Thôn Văn Định, Xã Ninh Đông, Thị Xã Ninh Hòa, Tỉnh Khánh Hòa, Việt Nam.',
  'app.traceability.product.shift': 'Ca sản xuất',
  'app.traceability.product.quantity': 'Số lượng sản xuất',
  'app.traceability.product.dateOfManufacture': 'Ngày sản xuất',
  'app.traceability.product.expirationDate': 'Ngày hết hạn',
  'app.traceability.material.code': 'Mã lô nguyên liệu',
  'app.traceability.material.type': 'Loại nguyên liệu đầu vào',
  'app.traceability.material.importedDate': 'Ngày nhập',
  'app.traceability.material.expiredDate': 'Ngày hết hạn',
  'app.traceability.material.weight': 'Trọng lượng nguyên liệu đầu vào',
  'app.traceability.supplier.generalInformation': 'Thông tin chung',
  'app.traceability.supplier.code': 'Mã nguồn nhập',
  'app.traceability.supplier.name': 'Tên nguồn nhập',
  'app.traceability.supplier.status': 'Tình trạng',
  'app.traceability.supplier.phone': 'Số điện thoại nguồn nhập',
  'app.traceability.supplier.address': 'Vị trí vùng nuôi',
  'app.traceability.supplier.diseaseTestingInformation': 'Thông tin kiểm định',
  'app.traceability.supplier.diseaseTestingStatus': 'Tình trạng kiểm định',
  'app.traceability.supplier.diseaseTestingDate': 'Ngày kiểm định',
  'app.traceability.supplier.diseaseTestingExpiredDate': 'Ngày hết hạn',
  'app.traceability.supplier.representativeInformation': 'Người đại diện',
  'app.traceability.supplier.representativeName': 'Tên người đại diện',
  'app.traceability.supplier.representativePhone': 'Số điện thoại',
  'app.traceability.supplier.representativeEmail': 'Email',
  'app.traceability.supplier.diseaseTestingNo': 'Số đăng ký xét nghiệm',
  'app.traceability.supplier.diseaseTestingDescription': 'Yêu cầu xét nghiệm',
  'app.traceability.supplier.diseaseTestingSamples': 'Số mẫu',
  'app.traceability.supplier.diseaseTestingNotation': 'Ký hiệu mẫu',
  'app.traceability.supplier.diseaseTestingMethod': 'Phương pháp xét nghiệm'
};
