import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
  noticeList: any[];
}

const initialState: State = {
  noticeList: []
};

const noticeSlice = createSlice({
  name: 'notice',
  initialState,
  reducers: {
    setNoticeList(state, action: PayloadAction<any[]>) {
      state.noticeList = action.payload;
    }
  }
});

export const { setNoticeList } = noticeSlice.actions;
// Custom selector to get noticeList length
export const selectNoticeListLength = createSelector(
  (state: any) => state.notice.noticeList,
  (noticeList) => noticeList.length
);

export default noticeSlice.reducer;
