import { TraceabilityHeader } from '@/components/traceability';
import { Layout } from 'antd';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import packageJson from '../../../package.json';
const { Content, Footer } = Layout;

const TraceabilityPage = () => {
  return (
    <>
      <Layout className="traceability-layout">
        <TraceabilityHeader></TraceabilityHeader>
        <Content className="main-content">
          <div className="main-content-inner">
            <Suspense fallback={null}>
              <Outlet />
            </Suspense>
          </div>
          <Footer style={{ textAlign: 'center', padding: '12px 6px' }}>
            MT NEST ©2023. Version {packageJson.version}. <br className="main-footer-br" />
            Copyright by{' '}
            <a href="https://amitgroup.vn" target="_blank" rel="noreferrer">
              AMIT GROUP
            </a>
          </Footer>
        </Content>
      </Layout>
    </>
  );
};

export default TraceabilityPage;
