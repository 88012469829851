export enum QR_STATUS {
  GENERATED = 2,
  NOT_GENERATED = 1,
  DELETED = 3
}

export enum QR_STATUS_CODE {
  GENERATED = 'GENERATED',
  NOT_GENERATED = 'NOT_GENERATED',
  DELETED = 'DELETED'
}

export enum TRACEABILITY_TYPE {
  MATERIAL = 1,
  PRODUCT = 2
}
