import { MenuList } from '@/interfaces/layout/menu.interface';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface State {
  menuList: MenuList;
}

const initialState = {
  menuList: []
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState: initialState,
  reducers: {
    setMenuList(state: State, action: PayloadAction<MenuList>) {
      state.menuList = action.payload;
    }
  }
});

export const { setMenuList } = layoutSlice.actions;

export default layoutSlice.reducer;
