export const zhCN_profile = {
  'app.user.profile.email': 'Email',
  'app.user.profile.role': 'Role',
  'app.user.profile.role.admin': 'Admin',
  'app.user.profile.role.staff': 'Staff',
  'app.user.profile.fullName': 'Full Name',
  'app.user.profile.phone': 'Phone',
  'app.user.profile.title': 'Profile',
  'app.user.profile.edit': 'Edit',
  'app.user.profile.logout': 'Logout',
  'app.user.profile.cancel': 'Cancel',
  'app.user.profile.editProfile': 'Edit Profile',
  'app.user.profile.save': 'Save'
};
