export const enUS_title = {
  'title.login': 'Login',
  'title.forgotPassword': 'Forgot Password',
  'title.resetPassword': 'Reset Password',
  'title.dashboard': 'Dashboard',
  'title.documentation': 'Documentation',
  'title.guide': 'Guide',
  'title.permission.route': 'Route Permission',
  'title.permission.button': 'Button Permission',
  'title.permission.config': 'Permission Config',
  'title.account': 'Account',
  'title.notFount': '404',
  'title.profile': 'Profile',
  'title.traceability': 'Traceability',
  'title.traceability.result': 'Traceability Result'
};
