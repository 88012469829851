import { notification } from 'antd';
import { ArgsProps } from 'antd/es/notification/interface';
import { ReactNode } from 'react';

export default function useNotification() {
  return {
    success: (message: string, description?: any, config?: ArgsProps) => {
      notification.success({
        message,
        description,
        ...config
      });
    },
    error: (message: string, description?: any, config?: ArgsProps) => {
      notification.error({
        message,
        description,
        ...config
      });
    },
    warning: (message: string, description?: any, config?: ArgsProps) => {
      notification.warning({
        message,
        description,
        ...config
      });
    },
    info: (message: string, description?: any, config?: ArgsProps) => {
      notification.info({
        message,
        description,
        ...config
      });
    }
  };
}
