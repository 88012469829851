import { intercepter, mock } from '../config';

const mockListUsers = new Array(100).fill(null).map((_, index) => ({
  id: index + 1,
  name: `User ${index + 1}`,
  email: `user_${index + 1}@gmail.com`,
  phone: `0123456${index + 1}`.padEnd(10, '0'),
  role: 'Admin'
}));

mock.mock('/user/list', 'post', (config: any) => {
  console.log(config);

  return intercepter({
    users: mockListUsers,
    pageIndex: 1,
    pageSize: 10,
    totalItems: mockListUsers.length
  });
});
