import { viVN_account } from './account';
import { viVN_globalTips } from './global/tips';
import { viVN_Material } from './material';
import { viVN_notice } from './notice';
import { viVN_notification } from './notification';
import { viVN_Product } from './product';
import { viVN_QrCode } from './qr-code';
import { viVN_Shipment } from './shipment';
import { viVN_Supplier } from './supplier';
import { viVN_Traceability } from './traceability';
import { viVN_user } from './user';

const en_US = {
  ...viVN_account,
  ...viVN_user,
  ...viVN_globalTips,
  ...viVN_notice,
  ...viVN_notification,
  ...viVN_Supplier,
  ...viVN_Material,
  ...viVN_Product,
  ...viVN_Shipment,
  ...viVN_QrCode,
  ...viVN_Traceability
};

export default en_US;
