import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createAsyncAction } from '../utils';
import { ListSupplierFilterParams, ListSupplierParams, ListSupplierResult, Supplier } from '@/interfaces/supplier';
import { SupplierService } from '@/services/supplier.service';
import { SortDirection } from '@/interfaces/general';
import { TraceabilityResult } from '@/interfaces/traceability';
import { TraceabilityService } from '@/services/traceability.service';

interface State {
  loading: boolean;
  code: string;
  data: TraceabilityResult | null;
}
const initialState: State = {
  loading: false,
  code: '',
  data: null
};
const traceabilityResultSlice = createSlice({
  name: 'traceability',
  initialState,
  reducers: {
    setTraceabilityResultState(state, { payload }: PayloadAction<Omit<State, 'loading'>>) {
      state.code = payload.code ?? '';
      state.data = payload.data ?? null;
    },
    setLoadingTraceabilityResult(state, { payload }: PayloadAction<boolean>) {
      state.loading = payload;
    }
  }
});

export const { setLoadingTraceabilityResult, setTraceabilityResultState } = traceabilityResultSlice.actions;
export default traceabilityResultSlice.reducer;

export const getTraceabilityResult = createAsyncAction((payload: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoadingTraceabilityResult(true));
      const res = await TraceabilityService.getTraceabilityResult(payload);
      dispatch(setTraceabilityResultState({ data: res.data, code: payload }));
      dispatch(setLoadingTraceabilityResult(false));
      return true;
    } catch (error) {
      dispatch(setTraceabilityResultState({ data: null, code: payload }));
      dispatch(setLoadingTraceabilityResult(false));
      return Promise.reject(error);
    }
  };
});
