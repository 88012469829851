export const viVN_profile = {
  'app.user.profile.email': 'Email',
  'app.user.profile.emailPlaceholder': 'nguyenvana@gmail.com',
  'app.user.profile.enterEmailMessage': 'Email không được bỏ trống',
  'app.user.profile.invalidEmailMessage': 'Email không hợp lệ',
  'app.user.profile.role': 'Vai trò',
  'app.user.profile.rolePlaceholder': 'Chọn vai trò người dùng',
  'app.user.profile.role.admin': 'Quản trị viên',
  'app.user.profile.role.staff': 'Nhân viên',
  'app.user.profile.selectRoleMessage': 'Chưa chọn vai trò của người dùng',
  'app.user.profile.fullName': 'Họ và tên',
  'app.user.profile.name': 'Tên người dùng',
  'app.user.profile.namePlaceholder': 'Nguyễn Văn A',
  'app.user.profile.phone': 'Số điện thoại',
  'app.user.profile.phonePlaceholder': '0324567789',
  'app.user.profile.invalidPhoneMessage': 'Số điện thoại không hợp lệ',
  'app.user.profile.title': 'Thông tin cá nhân',
  'app.user.profile.edit': 'Chỉnh sửa',
  'app.user.profile.logout': 'Đăng xuất',
  'app.user.profile.cancel': 'Hủy',
  'app.user.profile.editProfile': 'Chỉnh sửa thông tin cá nhân',
  'app.user.profile.editProfileSuccess': 'Cập nhật thông tin cá nhân thành công!',
  'app.user.profile.save': 'Lưu thông tin'
};
