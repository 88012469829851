export const viVN_title = {
  'title.login': 'Đăng nhập',
  'title.forgotPassword': 'Quên mật khẩu',
  'title.resetPassword': 'Đặt lại mật khẩu',
  'title.dashboard': 'Dashboard',
  'title.documentation': 'Documentation',
  'title.guide': 'Guide',
  'title.permission.route': 'Route Permission',
  'title.permission.button': 'Button Permission',
  'title.permission.config': 'Permission Config',
  'title.account': 'Account',
  'title.notFount': '404',
  'title.user.profile': 'Thông tin cá nhân',
  'title.user.management': 'Quản lý người dùng',
  'title.supplier.management': 'Quản lý nguồn nhập',
  'title.supplier.create': 'Thêm nguồn nhập',
  'title.supplier.update': 'Chỉnh sửa nguồn nhập',
  'title.material.management': 'Nhập nguyên liệu đầu vào',
  'title.material.create': 'Thêm nguyên liệu đầu vào',
  'title.material.update': 'Chỉnh sửa nguyên liệu đầu vào',
  'title.product.management': 'Quản lý sản phẩm đầu ra',
  'title.product.create': 'Thêm sản phẩm đầu ra',
  'title.product.update': 'Chỉnh sửa sản phẩm đầu ra',
  'title.shipment.management': 'Lô hàng sản xuất',
  'title.shipment.create': 'Thêm lô hàng sản xuất',
  'title.shipment.update': 'Chỉnh sửa lô hàng sản xuất',
  'title.qrCode.management': 'Quản lý mã QR',
  'title.qrCode.detail': 'Chi tiết mã QR',
  'title.traceability': 'Truy xuất nguồn gốc',
  'title.traceability.result': 'Kết quả truy xuất nguồn gốc'
};
