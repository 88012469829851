export const viVN_ShipmentManagement = {
  'app.shipment.management.title': 'Lô hàng sản xuất',
  'app.shipment.management.createTitle': 'Thêm lô hàng sản xuất',
  'app.shipment.management.createSuccessMessage': 'Thêm lô hàng sản xuất thành công',
  'app.shipment.management.updateTitle': 'Chỉnh sửa lô hàng sản xuất {code}',
  'app.shipment.management.updateSuccessMessage': 'Lưu thông tin thành công',
  'app.shipment.management.deleteConfirmTitle': 'Xác nhận xóa',
  'app.shipment.management.deleteConfirmMessage': 'Bạn có chắc chắn muốn xóa lô hàng {code}?',
  'app.shipment.management.deleteSuccessMessage': 'Xóa lô hàng thành công',
  'app.shipment.management.deleteMultipleConfirmMessage': 'Bạn có chắc chắn muốn xóa {count} lô hàng đã chọn?',
  'app.shipment.management.deleteMultipleSuccessMessage': 'Xóa {count} lô hàng thành công',
  'app.shipment.management.selectedRecords': 'Đã chọn {count} kết quả',
  'app.shipment.management.filterByProductionShift': 'Lọc theo ca sản xuất',
  'app.shipment.management.filterByStatus': 'Lọc theo trạng thái',
  'app.shipment.management.filterByQrStatus': 'Lọc theo trạng thái QR',
  'app.shipment.management.confirmCreateQRMessage': 'Bạn có chắc chắn muốn tạo mã QR cho lô hàng {code}?',
  'app.shipment.management.confirmReCreateQRMessage': 'Bạn có chắc chắn muốn tạo lại mã QR cho lô hàng {code}?',
  'app.shipment.management.confirmCreateTitle': 'Xác nhận tạo mới',
  'app.shipment.management.confirmCreateMessage':
    'Mã QR cho lô hàng sẽ được tạo. Bạn sẽ không thể chỉnh sửa thông tin khi đã tạo mới với trạng thái Đã khởi tạo QR code. Tiếp tục tạo?',
  'app.shipment.management.confirmUpdateTitle': 'Xác nhận đổi trạng thái',
  'app.shipment.management.confirmUpdateMessage':
    'Mã QR cho lô hàng {code} sẽ được tạo. Bạn sẽ không thể chỉnh sửa thông tin khi đã thay đổi trạng thái của lô hàng?',
  'app.shipment.management.generateQRSuccessMessage': 'Mã QR code cho mã lô {code} đã được tạo thành công.',
  'app.shipment.management.cannotDeleteTitle': 'Không thể xóa mã lô hàng',
  'app.shipment.management.cannotDeleteMessage': 'Không thể xóa mã lô hàng có trạng thái Đã khởi tạo mã QR'
};
