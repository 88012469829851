import { AuthState, LoginParams, ProfileParams, ProfileResult } from '@/interfaces/auth';
import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createAsyncAction } from '../utils';
import { AuthService } from '@/services/auth.service';

const initialState: AuthState = {
  logged: !!localStorage.getItem('t'),
  currentUser: null!
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth(state: AuthState, action: PayloadAction<Partial<AuthState>>) {
      Object.assign(state, action.payload);
    },
    setCurrentUser(state: AuthState, action: PayloadAction<Partial<ProfileResult>>) {
      state.currentUser = {
        ...state.currentUser,
        ...action.payload
      };
    }
  }
});

export const { setAuth, setCurrentUser } = authSlice.actions;

export default authSlice.reducer;

export const loginAsync = createAsyncAction<LoginParams, boolean>((payload: LoginParams) => {
  return async (dispatch) => {
    const loginRes = await AuthService.login(payload);

    if (loginRes.data) {
      localStorage.setItem('t', loginRes.data.accessToken);
      const profileRes = await AuthService.profile();

      dispatch(
        setAuth({
          logged: true,
          currentUser: profileRes.data
        })
      );

      return true;
    }

    return false;
  };
});

export const logoutAsync = () => {
  return async (dispatch: Dispatch) => {
    const res = await AuthService.logout();

    if (res) {
      localStorage.clear();
      dispatch(
        setAuth({
          logged: false
        })
      );
      return true;
    }
    return false;
  };
};

export const getProfile = () => {
  return async (dispatch: Dispatch) => {
    const { data } = await AuthService.profile();

    if (data) {
      dispatch(
        setCurrentUser({
          ...data
        })
      );
    }
  };
};

export const updateProfileAsync = createAsyncAction<ProfileParams, boolean>((payload: ProfileParams) => {
  return async (dispatch) => {
    const { data } = await AuthService.updateProfile(payload);

    if (data) {
      dispatch(
        setCurrentUser({
          ...data
        })
      );

      return true;
    }

    return false;
  };
});
