import { FC, useMemo } from 'react';
import { Menu } from 'antd';
import { MenuList } from '@/interfaces/layout/menu.interface';
import { useNavigate } from 'react-router-dom';
import { CustomIcon } from '../custom-icon';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalState } from '@/stores/reducer/global.reducer';

interface MenuProps {
  menuList: MenuList;
  openKey?: string;
  onChangeOpenKey: (key?: string) => void;
  selectedKey: string;
  onChangeSelectedKey: (key: string) => void;
}

export const MenuComponent: FC<MenuProps> = (props) => {
  const { menuList, openKey, onChangeOpenKey, selectedKey, onChangeSelectedKey } = props;
  const { device, locale } = useSelector((state) => state.global);
  const { currentUser } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentUserMenuList = useMemo(() => {
    return (
      menuList &&
      menuList.filter((menu) => {
        return (
          !menu.permissions ||
          !currentUser.authorities ||
          currentUser.authorities.length === 0 ||
          currentUser.authorities.includes('FULL_PERMISSION') ||
          currentUser.authorities.some((permission) => menu.permissions?.includes(permission))
        );
      })
    );
  }, [currentUser, menuList]);

  const getTitle = (menu: MenuList[0]) => {
    return (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <CustomIcon type={menu.icon!} />
        <span>{menu.label[locale]}</span>
      </span>
    );
  };

  const onMenuClick = (path: string) => {
    onChangeSelectedKey(path);
    navigate(path);
    if (device !== 'DESKTOP') {
      dispatch(setGlobalState({ collapsed: true }));
    }
  };

  const onOpenChange = (keys: string[]) => {
    const key = keys.pop();

    onChangeOpenKey(key);
  };

  return (
    <>
      {currentUser && (
        <>
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            openKeys={openKey ? [openKey] : []}
            onOpenChange={onOpenChange}
            onSelect={(k) => onMenuClick(k.key)}
            className="main-sider-menu text-2"
            items={currentUserMenuList.map((menu) => {
              return menu.children
                ? {
                    key: menu.code,
                    label: getTitle(menu),
                    children: menu.children.map((child) => ({
                      key: child.path,
                      label: child.label[locale]
                    }))
                  }
                : {
                    key: menu.path,
                    label: getTitle(menu)
                  };
            })}
          />
        </>
      )}
    </>
  );
};
