import { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Result, Button } from 'antd';
import { useLocale } from '@/locales';
import { RouteProps, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '@/stores/reducer/auth.reducer';
import { AuthService } from '@/services/auth.service';
import { Permission } from '@/interfaces/auth';

const PrivateRoute: FC<RouteProps & { permissions?: Permission[] }> = (props: PropsWithChildren<RouteProps & { permissions?: Permission[] }>) => {
  const { logged, currentUser } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { formatMessage } = useLocale();
  const location = useLocation();
  const dispatch = useDispatch();
  const hasPermission = useMemo(() => {
    return (
      !props.permissions ||
      (currentUser &&
        (!currentUser.authorities ||
          currentUser.authorities.length === 0 ||
          currentUser.authorities.includes('FULL_PERMISSION') ||
          currentUser.authorities.some((permission) => props.permissions?.includes(permission))))
    );
  }, [props.permissions, currentUser]);

  useEffect(() => {
    (async () => {
      if (localStorage.getItem('t')) {
        if (!currentUser) {
          await dispatch(getProfile());
        }
      }
    })();
  }, []);

  return logged ? (
    <>
      {!currentUser || !currentUser.authorities ? (
        <>
          <div className="screen">
            <div className="loading-container">
              <i></i>
              <i></i>
              <i></i>
              <i></i>
            </div>
          </div>
        </>
      ) : (
        <>
          {hasPermission ? (
            (props.element as React.ReactElement)
          ) : (
            <Result
              status="403"
              title="403"
              subTitle={formatMessage({ id: 'global.tips.unauthorized' })}
              extra={
                <Button type="primary" onClick={() => navigate(`/user/profile`, { replace: true })}>
                  {formatMessage({ id: 'global.tips.backHome' })}
                </Button>
              }
            />
          )}
        </>
      )}
    </>
  ) : (
    <Navigate to={`/auth/login${'?from=' + encodeURIComponent(location.pathname)}`} replace={true} />
  );
};

export default PrivateRoute;
