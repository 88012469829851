import { CommonService } from '@/core/interfaces/service';
import { ApiService } from '@/core/services/api.service';
import {
  ListQrCodeParams,
  ListQrCodeResult,
  DetailQrCodeParams,
  DetailQrCodeResult,
  DeleteMultipleQrCodeParams,
  DeleteQrCodeParams
} from '@/interfaces/qr-code';
import { ActivateQrCodeParams } from '@/interfaces/qr-code/activate.interface';
import { ExportQrCodeParams } from '@/interfaces/qr-code/export.interface';

export const QrCodeEndpoint = {
  List: '/traceability/search',
  Detail: '/traceability/detail',
  Delete: '/traceability/delete',
  DeleteMultiple: '/traceability/delete-multiple',
  GenerateQr: '/traceability/generate-qr',
  MaterialWeightUsed: '/traceability/supplier-material-weight-used',
  Activate: '/traceability/activate',
  Deactivate: '/traceability/deactivate',
  ActivateAllChildren: '/traceability/activate-all-children',
  DeactivateAllChildren: '/traceability/deactivate-all-children',
  ActivateMultiple: '/traceability/activate-multiple',
  DeactivateMultiple: '/traceability/deactivate-multiple',
  Renew: '/traceability/renew',
  RenewAllChildren: '/traceability/renew-all-children',
  ExportQrCode: '/traceability/export-qr'
};

class QrCodeService implements CommonService {
  static instance: QrCodeService;

  constructor() {
    if (QrCodeService.instance) {
      throw new Error('Error: Instantiation failed: Use QrCodeService.getInstance() instead of new.');
    }
    QrCodeService.instance = this;
  }

  static getInstance() {
    if (!QrCodeService.instance) {
      QrCodeService.instance = new QrCodeService();
    }
    return QrCodeService.instance;
  }

  listQrCode(params: ListQrCodeParams) {
    return ApiService.post<ListQrCodeResult>(QrCodeEndpoint.List, params);
  }

  detailQrCode(params: DetailQrCodeParams) {
    return ApiService.post<DetailQrCodeResult>(QrCodeEndpoint.Detail, params);
  }

  deleteQrCode(params: DeleteQrCodeParams) {
    return ApiService.post(QrCodeEndpoint.Delete, params);
  }

  deleteMultipleQrCodes(params: DeleteMultipleQrCodeParams) {
    return ApiService.post<DeleteMultipleQrCodeParams>(QrCodeEndpoint.DeleteMultiple, params);
  }

  deactivateQrCode(params: ActivateQrCodeParams) {
    return ApiService.post<ActivateQrCodeParams>(QrCodeEndpoint.Deactivate, params);
  }

  activateQrCode(params: ActivateQrCodeParams) {
    return ApiService.post<ActivateQrCodeParams>(QrCodeEndpoint.Activate, params);
  }

  activateAllChildren(params: ActivateQrCodeParams) {
    return ApiService.post<ActivateQrCodeParams>(QrCodeEndpoint.ActivateAllChildren, params);
  }

  deactivateAllChildren(params: ActivateQrCodeParams) {
    return ApiService.post<ActivateQrCodeParams>(QrCodeEndpoint.DeactivateAllChildren, params);
  }

  reCreateQRCode(params: { recordId: number; traceabilityType: number }) {
    return ApiService.post(QrCodeEndpoint.Renew, params);
  }

  reCreateAllChildren(params: { id: number }) {
    return ApiService.post(QrCodeEndpoint.RenewAllChildren, params);
  }

  activateMultipleQrCodes(params: { ids: number[] }) {
    return ApiService.post(QrCodeEndpoint.ActivateMultiple, params);
  }

  deactivateMultipleQrCodes(params: { ids: number[] }) {
    return ApiService.post(QrCodeEndpoint.DeactivateMultiple, params);
  }

  exportQrCodes(params: ExportQrCodeParams) {
    return fetch(
      `${import.meta.env.VITE_API_PROTOCOL}://${import.meta.env.VITE_API_HOST}/${import.meta.env.VITE_API_PREFIX}/${
        import.meta.env.VITE_API_VERSION
      }${QrCodeEndpoint.ExportQrCode}`,
      {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('t') || ''}`
        }
      }
    ).then((res) => res.blob());
  }
}

const instance = QrCodeService.getInstance();

export { instance as QrCodeService };
