import { CommonService } from '@/core/interfaces/service';
import { ApiService } from '@/core/services/api.service';
import {
  CreateProductParams,
  CreateProductResult,
  DeleteProductParams,
  DeleteMultipleProductParams,
  DetailProductParams,
  DetailProductResult,
  ListProductParams,
  ListProductResult,
  UpdateProductParams,
  UpdateProductResult
} from '@/interfaces/product';

export const ProductEndpoint = {
  List: '/products/search',
  Create: '/products/add',
  Update: '/products/edit',
  Delete: '/products/delete',
  Detail: '/products/detail',
  DeleteMultiple: '/products/delete-multiple'
};

class ProductService implements CommonService {
  static instance: ProductService;

  constructor() {
    if (ProductService.instance) {
      throw new Error('Error: Instantiation failed: Use ProductService.getInstance() instead of new.');
    }
    ProductService.instance = this;
  }

  static getInstance() {
    if (!ProductService.instance) {
      ProductService.instance = new ProductService();
    }
    return ProductService.instance;
  }

  createProduct(formData: FormData) {
    return ApiService.post<CreateProductResult>(ProductEndpoint.Create, formData);
    // return fetch(`${ProductEndpoint.Create}`, {
    //   method: 'post',
    //   body: formData
    // }).then(res => res.json());
  }

  listProduct(params: ListProductParams) {
    return ApiService.post<ListProductResult>(ProductEndpoint.List, params);
  }

  deleteProduct(params: DeleteProductParams) {
    return ApiService.post(ProductEndpoint.Delete, params);
  }

  detailProduct(params: DetailProductParams) {
    return ApiService.post<DetailProductResult>(ProductEndpoint.Detail, params);
  }

  updateProduct(formData: FormData) {
    return ApiService.post<UpdateProductResult>(ProductEndpoint.Update, formData);
  }

  deleteMultipleProducts(params: DeleteMultipleProductParams) {
    return ApiService.post<DeleteMultipleProductParams>(ProductEndpoint.DeleteMultiple, params);
  }
}

const instance = ProductService.getInstance();

export { instance as ProductService };
