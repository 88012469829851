export const koKR_title = {
  'title.login': '로그인',
  'title.dashboard': '계기반',
  'title.documentation': '선적 서류 비치',
  'title.guide': '가이드',
  'title.permission.route': '경로 권한',
  'title.permission.button': '버튼 권한',
  'title.permission.config': '권한 구성',
  'title.account': '계정',
  'title.notFount': '404',
  'title.traceability': 'Traceability',
  'title.traceability.result': 'Traceability Result'
};
