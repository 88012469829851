import { Response } from '@/core/interfaces/api';
import { CommonService } from '@/core/interfaces/service';
import { ApiService } from '@/core/services/api.service';
import { PaginationResult } from '@/interfaces/general';
import { DeleteUserParams, DeleteUserResult, ListUserParams, ListUserResult, UpdateUserParams, UpdateUserResult } from '@/interfaces/user';
import { CreateUserParams } from '@/interfaces/user/create.interface';

export const UserEndpoint = {
  List: '/users/search',
  Create: '/users/add',
  Update: '/users/edit',
  Delete: '/users/delete',
  Detail: '/users/detail'
};

class UserService implements CommonService {
  static instance: UserService;

  constructor() {
    if (UserService.instance) {
      throw new Error('Error: Instantiation failed: Use UserService.getInstance() instead of new.');
    }
    UserService.instance = this;
  }

  static getInstance() {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  createUser(data: CreateUserParams) {
    return ApiService.post(UserEndpoint.Create, data);
  }

  getListUser(params: ListUserParams) {
    return ApiService.post<ListUserResult>(UserEndpoint.List, params);
  }

  deleteUser(params: DeleteUserParams) {
    return ApiService.post<DeleteUserResult>(UserEndpoint.Delete, params);
  }

  updateUser(data: UpdateUserParams) {
    return ApiService.post<UpdateUserResult>(UserEndpoint.Update, data);
  }
}

const instance = UserService.getInstance();

export { instance as UserService };
