import { combineReducers } from '@reduxjs/toolkit';
import globalReducer from './reducer/global.reducer';
import authReducer from './reducer/auth.reducer';
import layoutReducer from './reducer/layout.reducer';
import noticeReducer from './reducer/notice.reducer';
import userReducer from './reducer/user.reducer';
import supplierReducer from './reducer/supplier.reducer';
import materialReducer from './reducer/material.reducer';
import productReducer from './reducer/product.reducer';
import shipmentReducer from './reducer/shipment.reducer';
import qrCodeReducer from './reducer/qr-code.reducer';
import traceabilityResultReducer from './reducer/traceability-result.reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  global: globalReducer,
  layout: layoutReducer,
  notice: noticeReducer,
  user: userReducer,
  supplier: supplierReducer,
  material: materialReducer,
  product: productReducer,
  shipment: shipmentReducer,
  qrCode: qrCodeReducer,
  traceabilityResult: traceabilityResultReducer
});

export default rootReducer;
