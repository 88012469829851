import { koKR_account } from './account';
import { koKR_avatorDropMenu } from './user/avatorDropMenu';
import { koKR_tagsViewDropMenu } from './user/tagsViewDropMenu';
import { koKR_title } from './user/title';
import { koKR_globalTips } from './global/tips';
import { koKR_notice } from './notice';
import { koKR_profile } from './profile';

const ko_KR = {
  ...koKR_account,
  ...koKR_avatorDropMenu,
  ...koKR_tagsViewDropMenu,
  ...koKR_title,
  ...koKR_globalTips,
  ...koKR_notice,
  ...koKR_profile
};

export default ko_KR;
