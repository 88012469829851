import * as Axios from 'axios';
import { ResponseInterceptorReject, ResponseInterceptorResolve } from '../interceptors/response';
import { RequestInterceptorReject, RequestInterceptorResolve } from '../interceptors/request';

import { CommonService, CoreApiService } from '../interfaces/service';
import { Response } from '../interfaces/api';

class ApiService implements CoreApiService, CommonService<any> {
  static instance: ApiService;

  static getInstance(): ApiService {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService();
    }
    return ApiService.instance;
  }

  axios: Axios.AxiosInstance;

  constructor() {
    this.axios = Axios.default.create({
      baseURL: `${import.meta.env.VITE_API_PROTOCOL}://${import.meta.env.VITE_API_HOST}/${import.meta.env.VITE_API_PREFIX}/${
        import.meta.env.VITE_API_VERSION
      }`,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    this.axios.interceptors.request.use(RequestInterceptorResolve, RequestInterceptorReject);
    this.axios.interceptors.response.use(ResponseInterceptorResolve, ResponseInterceptorReject);
  }

  get<T>(url: string, config?: Partial<Axios.AxiosRequestConfig>): Promise<Response<T>> {
    return this.axios.get(url, config);
  }

  post<T>(url: string, data?: any, config?: Partial<Axios.AxiosRequestConfig>): Promise<Response<T>> {
    return this.axios.post(url, data, config);
  }

  put<T>(url: string, data?: any, config?: Partial<Axios.AxiosRequestConfig>): Promise<Response<T>> {
    return this.axios.put(url, data, config);
  }
  delete<T>(url: string, config?: Partial<Axios.AxiosRequestConfig>): Promise<Response<T>> {
    return this.axios.delete(url, config);
  }
}

const instance = ApiService.getInstance();

export { instance as ApiService };
