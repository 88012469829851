export const koKR_globalTips = {
  'global.tips.notfound': '죄송합니다. 방문한 페이지가 존재하지 않습니다.',
  'global.tips.unauthorized': '죄송합니다. 이 페이지에 액세스할 수 있는 권한이 없습니다.',
  'global.tips.loginResult': '이 페이지가 보이면 로그인이 된 것입니다.',
  'global.tips.goToLogin': '로그인으로 이동',
  'global.tips.username': '사용자 이름',
  'global.tips.enterUsernameMessage': '사용자 이름을 입력하세요!',
  'global.tips.password': '비밀번호',
  'global.tips.enterPasswordMessage': '비밀번호를 입력해주세요!',
  'global.tips.rememberUser': '사용자 기억',
  'global.tips.login': '로그인',
  'global.tips.backHome': '집으로 돌아가',
  'global.tips.operation': '작업',
  'global.tips.authorize': '승인하다',
  'global.tips.delete': '삭제',
  'global.tips.create': '창조하다',
  'global.tips.modify': '수정하다',
  'global.tips.search': '찾다',
  'global.tips.reset': '초기화',
  'global.tips.deleteConfirm': '이 항목을 삭제하시겠습니까?',
  'global.tips.loading': '로드 중...',
  'global.tips.theme.darkTooltip': '어두운 테마로 전환',
  'global.tips.theme.lightTooltip': '밝은 테마로 전환',
  'global.tips.theme.noticeTooltip': '알림'
};
