export const viVN_QrCodeInformation = {
  'app.qrCode.information.shipment.title': 'Thông tin lô hàng sản xuất',
  'app.qrCode.information.material.title': 'Thông tin nguồn gốc nguyên liệu',
  'app.qrCode.information.traceabilityType': 'Loại truy xuất',
  'app.qrCode.information.generalInformation': 'Thông tin chung',
  'app.qrCode.information.code': 'Mã QR',
  'app.qrCode.information.qrId': 'QR ID',
  'app.qrCode.information.status': 'Trạng thái',
  'app.qrCode.information.productionShift': 'Ca sản xuất',
  'app.qrCode.information.shipmentCode': 'Mã lô hàng',
  'app.qrCode.information.shipmentName': 'Tên lô hàng',
  'app.qrCode.information.dateOfManufacture': 'Ngày sản xuất',
  'app.qrCode.information.expirationDate': 'Ngày hết hạn',
  'app.qrCode.information.productOutput': 'Sản phẩm đầu ra',
  'app.qrCode.information.productQuantity': 'Số lượng sản xuất',
  'app.qrCode.information.material': 'Nguyên liệu đầu vào',
  'app.qrCode.information.materialType': 'Loại nguyên liệu',
  'app.qrCode.information.shipmentMaterial': 'Lô nguyên liệu đầu vào',
  'app.qrCode.information.shipmentMaterialCode': 'Mã lô nguyên liệu',
  'app.qrCode.information.materialTypeInput': 'Loại nguyên liệu đầu vào',
  'app.qrCode.information.supplier': 'Nguồn nhập',
  'app.qrCode.information.materialWeight': 'Trọng lượng nguyên liệu',
  'app.qrCode.information.importedDate': 'Ngày nhập',
  'app.qrCode.information.activated': 'Đang kích hoạt',
  'app.qrCode.information.deactivated': 'Chưa kích hoạt',
  'app.qrCode.information.createdDate': 'Ngày tạo',
  'app.qrCode.information.qrList': 'Danh sách mã QR',
  'app.qrCode.information.supplierPhone': 'Số điện thoại nguồn nhập',
  'app.qrCode.information.supplierRepresentative': 'Người đại diện',
  'app.qrCode.information.supplierRepresentativePhone': 'Số điện thoại',
  'app.qrCode.information.supplierLatLng': 'Tọa độ',
  'app.qrCode.information.supplierAddress': 'Vị trí vùng nuôi',
  'app.qrCode.information.supplierInformation': 'Thông tin nguồn nhập',
  'app.qrCode.information.supplierDiseaseTestingStatus': 'Tình trạng',
  'app.qrCode.information.supplierDiseaseTestingAt': 'Ngày kiểm định',
  'app.qrCode.information.supplierDiseaseTestingExpiredAt': 'Ngày hết hạn',
  'app.qrCode.information.supplierDiseaseTestingNo': 'Số đăng ký xét nghiệm',
  'app.qrCode.information.supplierDiseaseTestingDescription': 'Yêu cầu xét nghiệm',
  'app.qrCode.information.supplierDiseaseTestingSamples': 'Số mẫu',
  'app.qrCode.information.supplierDiseaseTestingNotation': 'Ký hiệu mẫu',
  'app.qrCode.information.supplierDiseaseTestingMethod': 'Phương pháp xét nghiệm',
  'app.qrCode.information.url': 'Đường liên kết'
};
