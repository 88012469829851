import { intercepter, mock } from '../config';

const mockUser = {
  id: 1,
  name: 'Nguyễn Văn A',
  phone: '0992572859',
  email: 'nguyenvana@gmail.com',
  role: 'Staff'
};
mock.mock('/user/create', 'post', intercepter(mockUser));
