import { CommonService } from '@/core/interfaces/service';
import { ApiService } from '@/core/services/api.service';
import { MenuList } from '@/interfaces/layout/menu.interface';
import { Notice } from '@/interfaces/layout/notice.interface';

class LayoutService implements CommonService<LayoutService> {
  static instance: LayoutService;

  static getInstance() {
    if (!LayoutService.instance) {
      LayoutService.instance = new LayoutService();
    }
    return LayoutService.instance;
  }

  constructor() {
    if (LayoutService.instance) {
      throw new Error('Error: Instantiation failed: Use LayoutService.getInstance() instead of new.');
    }
    LayoutService.instance = this;
  }

  getMenuList() {
    return ApiService.get<MenuList>('/user/menu', {
      baseURL: '/'
    });
  }

  getNoticeList() {
    return ApiService.get<Notice[]>('/user/notice', {
      baseURL: '/'
    });
  }
}

const instance = LayoutService.getInstance();
export { instance as LayoutService };
