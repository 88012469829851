import { Outlet } from 'react-router-dom';
import './index.scss';
import { Card, Row } from 'antd';
import logo from '@/assets/logo/logo_yen-800x800.png';

const AuthLayoutPage = () => {
  return (
    <>
      <div className="auth-page">
        <Card className="auth-card" bordered={false}>
          <Row justify={'center'}>
            <img className="auth-logo" src={logo} />
          </Row>
          <Outlet />
        </Card>
      </div>
    </>
  );
};
export default AuthLayoutPage;
