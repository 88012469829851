export const viVN_SupplierManagement = {
  'app.supplier.management.title': 'Quản lý nguồn nhập',
  'app.supplier.management.createTitle': 'Thêm nguồn nhập',
  'app.supplier.management.createSuccessMessage': 'Thêm nguồn nhập thành công',
  'app.supplier.management.deleteConfirmMessage': 'Bạn có chắc chắn muốn xóa nguồn nhập {name} không?',
  'app.supplier.management.cannotDeleteMessage': 'Không thể xóa vì {name} đang được tồn tại trong thông tin của nguyên liệu!',
  'app.supplier.management.deleteConfirmTitle': 'Xác nhận xóa nguồn nhập',
  'app.supplier.management.deleteSuccessMessage': 'Xóa nguồn nhập thành công',
  'app.supplier.management.updateTitle': 'Chỉnh sửa nguồn nhập {code}',
  'app.supplier.management.updateSuccessMessage': 'Chỉnh sửa nguồn nhập thành công',
  'app.supplier.management.selectedRecords': 'Đã chọn {count} kết quả',
  'app.supplier.management.deleteMultipleConfirmMessage': 'Bạn có chắc chắn muốn xóa {count} nguồn nhập đã chọn không?',
  'app.supplier.management.deleteMultipleSuccessMessage': 'Xóa {count} nguồn nhập thành công',
  'app.supplier.management.cannotDeleteMultipleMessage': 'Không thể xóa vì một số nguồn nhập đang được tồn tại trong thông tin của nguyên liệu!'
};
