import { CommonService } from '@/core/interfaces/service';
import { ApiService } from '@/core/services/api.service';
import { TraceabilityResultResponse } from '@/interfaces/traceability';

export const TraceabilityEndpoint = {
  Result: '/traceability/public/{code}'
};
class TraceabilityService implements CommonService {
  static instance: TraceabilityService;

  constructor() {
    if (TraceabilityService.instance) {
      throw new Error('Error: Instantiation failed: Use TraceabilityService.getInstance() instead of new.');
    }
    TraceabilityService.instance = this;
  }

  static getInstance() {
    if (!TraceabilityService.instance) {
      TraceabilityService.instance = new TraceabilityService();
    }
    return TraceabilityService.instance;
  }

  getTraceabilityResult(code: string) {
    return ApiService.get<TraceabilityResultResponse>(TraceabilityEndpoint.Result.replace('{code}', code));
  }
}

const instance = TraceabilityService.getInstance();

export { instance as TraceabilityService };
