import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createAsyncAction } from '../utils';
import { SortDirection } from '@/interfaces/general';
import { QrCodeService } from '@/services/qr-code.service';
import { ListQrCodeFilterParams, ListQrCodeParams, ListQrCodeResult, QrCode } from '@/interfaces/qr-code';

type State = ListQrCodeFilterParams & {
  loading: boolean;
  qrCodes: QrCode[];
  selectedQrCodes: QrCode[];
  pageIndex: number;
  pageSize: number;
  totalItems: number;
  sortBy: string;
  sortDirection: SortDirection;
};

const initialState: State = {
  loading: false,
  qrCodes: [],
  selectedQrCodes: [],
  pageIndex: 0,
  pageSize: 10,
  totalItems: 0,
  sortBy: 'id',
  sortDirection: 'DESC',
  code: null,
  type: null,
  isActivated: null,
  parentId: null
};
const qrCodeSlice = createSlice({
  name: 'qrCode',
  initialState,
  reducers: {
    setLoadingQrCodes(state, { payload }: PayloadAction<boolean>) {
      state.loading = payload;
    },
    setQrCodeState(state, { payload }: PayloadAction<ListQrCodeResult & ListQrCodeFilterParams>) {
      state.qrCodes = payload.items ?? [];
      state.pageIndex = payload.pageIndex ?? 0;
      state.pageSize = payload.pageSize ?? 0;
      state.totalItems = payload.totalItems ?? 0;
      state.code = payload.code ?? null;
      state.type = payload.type ?? null;
      state.isActivated = payload.isActivated ?? null;
      state.parentId = payload.parentId ?? null;
      state.selectedQrCodes = state.selectedQrCodes.filter((u) => payload.items.find((s) => s.id === u.id));
    },
    addSelectedQrCode(state, { payload }: PayloadAction<QrCode>) {
      state.selectedQrCodes = [...state.selectedQrCodes, payload];
    },
    removeSelectedQrCode(state, { payload }: PayloadAction<QrCode>) {
      state.selectedQrCodes = state.selectedQrCodes.filter((item) => item.id !== payload.id);
    },
    setSelectedQrCodes(state, { payload }: PayloadAction<QrCode[]>) {
      state.selectedQrCodes = payload;
    }
  }
});

export const { setLoadingQrCodes, setQrCodeState, addSelectedQrCode, removeSelectedQrCode, setSelectedQrCodes } = qrCodeSlice.actions;
export default qrCodeSlice.reducer;

export const getQrCodes = createAsyncAction((payload: ListQrCodeParams) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLoadingQrCodes(true));
      const res = await QrCodeService.listQrCode(payload);
      dispatch(
        setQrCodeState({
          ...res.data,
          pageSize: payload.pageSize,
          code: payload.code,
          type: payload.type,
          isActivated: payload.isActivated,
          parentId: payload.parentId
        })
      );
      dispatch(setLoadingQrCodes(false));
      return true;
    } catch (error) {
      dispatch(
        setQrCodeState({
          items: [],
          pageIndex: 0,
          totalItems: 0,
          pageSize: payload.pageSize,
          code: payload.code,
          type: payload.type,
          isActivated: payload.isActivated,
          parentId: payload.parentId
        })
      );
      dispatch(setLoadingQrCodes(false));
      return Promise.reject(error);
    }
  };
});
