import { MenuList } from '@/interfaces/layout/menu.interface';
import { mock, intercepter } from '../config';

const mockMenuList: MenuList = [
  {
    code: 'user',
    label: {
      vi_VN: 'Quản lý người dùng',
      en_US: 'User management',
      zh_CN: '用户管理',
      ko_KR: '사용자 관리'
    },
    icon: 'user',
    path: '/user',
    permissions: ['USER_MANAGEMENT']
  },
  {
    code: 'supplier',
    label: {
      vi_VN: 'Quản lý nguồn nhập',
      en_US: 'Supplier management',
      zh_CN: '供应商管理',
      ko_KR: '공급 업체 관리'
    },
    icon: 'supplier',
    path: '/supplier',
    permissions: ['SUPPLIER_MANAGEMENT']
  },
  {
    code: 'material',
    label: {
      vi_VN: 'Nhập nguyên liệu đầu vào',
      en_US: 'Material management',
      zh_CN: '原料管理',
      ko_KR: '원재료 관리'
    },
    icon: 'material',
    path: '/material',
    permissions: ['SUPPLIER_MATERIAL_MANAGEMENT']
  },
  {
    code: 'product',
    label: {
      vi_VN: 'Quản lý sản phẩm đầu ra',
      en_US: 'Product Management',
      zh_CN: '产品管理',
      ko_KR: '제품 관리'
    },
    icon: 'product',
    path: '/product',
    permissions: ['PRODUCT_MANAGEMENT']
  },
  {
    code: 'shipment',
    label: {
      vi_VN: 'Lô hàng sản xuất',
      en_US: 'Shipment Management',
      zh_CN: '产品管理',
      ko_KR: '제품 관리'
    },
    icon: 'shipment',
    path: '/shipment',
    permissions: ['MANUFACTURE_MANAGEMENT']
  },
  {
    code: 'qr-code',
    label: {
      vi_VN: 'Quản lý mã QR',
      en_US: 'QR Code Management',
      zh_CN: 'QR码管理',
      ko_KR: 'QR 코드 관리'
    },
    icon: 'qr-code',
    path: '/qr-code',
    permissions: ['TRACEABILITY_MANAGEMENT']
  }
];

mock.mock('/user/menu', 'get', intercepter(mockMenuList));
